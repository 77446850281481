<template>
  <section class="pc" v-if="!isMobile" style="min-height: 800px;">
    <!--    <h2 class="subTitle">실시간</h2>-->
    <div class="game_contents" style="min-height: 500px;">
      <table style="width: 86%;" >
        <tbody>
        <tr>
          <!--    왼쪽사이드바      -->
          <SideBar v-if="originList" :origin-list="originList" :filter-by-games="filterByGames" :select-game="selectGame" :check-block-game="checkBlockGame"></SideBar>
          <td style="text-align: center; width:15%;" v-else>
            <Loading></Loading>
          </td>
          <!-- left -->
          <td style="padding:0 10px 0 10px; width: 90%" v-if="lists">
            <List v-if="!selectedGame" :origin-list="originList" :wait-list="waitList" :is-loading="isLoading"  :add-cart="addCart" :check-cart="checkCart" :selected-game="selectGame" :check-block-game="checkBlockGame"></List>
            <SelectedGame v-if="selectedGame" :game="selectedGame" :add-cart="addCart" :check-cart="checkCart" :move-back="moveBack" :check-block-game="checkBlockGame"></SelectedGame>
          </td>
          <td style="padding:0 10px 0 10px; text-align: center; width: 90%;" v-else>
            <Loading></Loading>
          </td>

          <NewCart v-if="user"></NewCart>
          <NewCartNotLogin v-else></NewCartNotLogin>
          <!-- <td class="game_right_wrap" v-if="!sticky" valign="top" style="position: fixed; width: 10%;" >
            <NewCart :toggle-stick="toggleStick" :fixed="false"></NewCart>
          </td>
          <td class="game_right_wrap" v-if="sticky" valign="top" style="position: absolute; width: 10%;">
            <NewCart :toggle-stick="toggleStick" :fixed="false"></NewCart>
          </td> -->
          <!--          <td class="game_right_wrap" style="text-align: center; right:80%;" valign="top" v-else>-->
          <!--            <Loading></Loading>-->
          <!--          </td>-->
        </tr>
        </tbody>
      </table>
    </div>
  </section>
  <div v-else>
    <div class="header2">
      <div class="left">
        <router-link to="/index" class="btn-prev"></router-link>
      </div>
      <h2>인플레이</h2>
    </div>
    <div class="categorys">
      <button @click="changeCategory('')" :class="{'active': this.category === ''}">전체</button>
      <button @click="changeCategory(210)" :class="{'active': this.category === 210}"><img src="@/assets/img/ico/1.png"></button>
      <button @click="changeCategory(220)" :class="{'active': this.category === 220}"><img src="@/assets/img/ico/11.png"></button>
      <button @click="changeCategory(212)" :class="{'active': this.category === 212}"><img src="@/assets/img/ico/3.png"></button>
      <!--      <button @click="changeCategory('')"><img src="@/assets/img/ico/4.png"></button>-->
      <button @click="changeCategory(214)" :class="{'active': this.category === 214}"><img src="@/assets/img/ico/5.png"></button>
      <button @click="changeCategory(211)" :class="{'active': this.category === 211}"><img src="@/assets/img/ico/6.png"></button>
      <button @click="changeCategory(267)" :class="{'active': this.category === 267}"><img src="@/assets/img/ico/2.png"></button>
      <button @click="changeCategory(215)" :class="{'active': this.category === 215}"><img src="@/assets/img/ico/77.png"></button>
      <!--      <button @click="changeCategory('')"><img src="@/assets/img/ico/44.png"></button>-->
    </div>

    <!-- 인디케이터 -->
    <div class="m-game-wrap" v-if="!isLoading" style="min-height: 300px; text-align: center; padding-top: 50px;">
      <div class="m-game-body">
        <div class="m-game">
          <Loading></Loading>
        </div>
      </div>
    </div>

    <!-- 인디케이터 -->
    <div class="m-game-wrap" v-if="isLoading && lists.length === 0" style="min-height: 100px; text-align: center;">
      <div class="m-game-body">
        <div class="m-game">
          <div class="team">
            <div class="name">
              <span>진행중인 경기가</span>
              <span></span>
              <span>없습니다.</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <MList v-if="isLoading && lists.length > 0" :origin-list="lists" :add-cart="addCart" :check-cart="checkCart" :check-block-game="checkBlockGame"></MList>

    <!-- 경기일정   -->
    <div class="m-game-wrap">
      <div class="m-game-head">
        <div class="tit">
          <strong>경기일정</strong>
        </div>
      </div>
      <div class="m-game-body bg-white" v-for="(row, index) in waitList" :key="'inplayWaitList' + index ">
        <div class="m-game">
          <div class="day">
            <p class="t3 mb10" style="font-weight: bold;">{{ row.startTime | formatDate('YYYY-MM-DD HH:mm') }}</p>
            <img v-if="row.sport.icon" :src="row.sport.icon" class="m-r-5 mb10">
            <span class="t2" style="font-weight: bold;">{{ row.league | leagueName }}</span>
            <img style=" margin-right: 10px; margin-left: 10px;" v-if="row.league.icon" :src="row.league.icon"
                 alt="">
            <img style=" margin-right: 10px; margin-left: 10px;" v-else-if="row.location.icon" :src="row.location.icon"
                 alt="">
          </div>
          <div class="team">
            <div class="name">
              <span style="font-weight: bold;">{{ row.homeTeam  | teamName }}</span>
              <span> vs </span>
              <span style="font-weight: bold;">{{ row.awayTeam  | teamName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-aside-live"></div>
    <transition name="fade">
      <MCart v-if="user && this.cartItem.length > 0"></MCart>
    </transition>
  </div>

</template>

<script>
// 데스크탑
import SideBar from '../../../../components/Game/Sports/Inplay/SideBar'
import List from '../../../../components/Game/Sports/Inplay/InplayList'
import SelectedGame from '../../../../components/Game/Sports/Inplay/InplaySelectedGame'
import NewCart from '../../../../components/Betting/InplayCartNew'
import NewCartNotLogin from '@/components/Betting/InplayCartNewNotLogin'

// 모바일
import MCart from '../../../../components/Betting/MInplayCartNew'
import MList from '../../../../components/Game/Sports/Inplay/MInplayList'

//공통
import Loading from '../../../../components/Loading'
import MSG from '../../../../contants/msg'
import {mapState} from "vuex";
export default {
  name: "Inplay",
  components: {
    SideBar,
    List,
    SelectedGame,
    NewCart,
    NewCartNotLogin,
    MCart,
    MList,
    Loading
    // RingLoader,
  },
  data: function(){
    return {
      type: 'korean',
      category: null,
      isLoading: false,
      selectedGame: null,
      search: false,
      keyword: null,
      sticky: false,
      lists : null,
      payload: {}
    }
  },
  computed: {
    ...mapState({
      originList: state => state.inplay.originList,
      waitList: state => state.inplay.waitList,
      user: state => state.user.user,
      isMobile: state => state.page.isMobile,
      cartItem: state => state.inplay.cart,
    }),
    /* 해당경기의 베팅을 방지하는 설정*/
    blockSetting: function(){
      return this.$store.getters['INPLAY_SETTING'];
    }
  },
  watch:{
    selectedGame(val){
      if (!val){
        this.lists = null;
        this.initFolder();
      }
    }
  },
  mounted: function() {
    if (this.user){
      const config = this.user.members_detail_setting;
      if (config.베팅권한.인플레이.active) {
        this.$store.dispatch('SHOW_ERR_MSG2','인플레이는 점검중입니다.');
      }
    }
    this.$store.dispatch('ALL_INPLAY_CLEAR_ITEM', '인플레이')
    this.initFolder()
    this.reqWait();
    this.interval = setInterval(this.initFolder2, 20000);
    // this.reqWait()
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    // 경기요청
    initFolder(payload){
      this.isLoading = false;
      this.$store.dispatch('GET_INPLAY_LIST', { payload }).then(() => {
        this.isLoading = true;
        this.lists = this.originList;
      })
    },
    // 경기요청
    initFolder2(payload){
      this.$store.dispatch('GET_INPLAY_LIST', { payload }).then(() => {
        this.lists = this.originList;
      })
    },
    reqWait(){
      this.$store.dispatch('GET_INPLAY_WAIT_LIST', { payload: {} })
    },
    // 게임종목
    changeCategory: function(category){
      this.category = category;
      if (category === '') {
        return this.lists = this.originList;
      }
      this.lists = this.originList.filter(e=> e.sportSeq === category);
    },
    // 베팅카트에 담기
    addCart: function (bets, folders, game) {
      if (this.user){
        const config = this.user.members_detail_setting;
        if (!config.베팅권한.한국형.active) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.authorization);
      } else return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.isNotLogin);

      const cartItem = {
        gameId: game.id,
        gameSeq: game.seq,
        betsSeq: bets.seq,
        betsStatus: bets.status,
        betsId: bets.id,
        betsName: bets.name,
        betsUpdated: false,
        betsPrevOdds: bets.price,
        sportsId: game.sportId,
        foldersId: folders.id,
        foldersSeq: folders.seq,
        foldersType: folders.type,
        odds: bets.price,
        line: bets.line ? bets.line : null,
        leagueNameKr: game.league.nameKr,
        leagueNameEn: game.league.nameEn,
        leagueNameIcon: game.league.icon,
        sportNameKr: game.sport.nameKr,
        sportNameEn: game.sport.nameEn,
        sportNameIcon: game.sport.icon,
        homeNameKr: game.homeTeam.nameKr,
        homeNameEn: game.homeTeam.nameEn,
        awayNameKr: game.awayTeam.nameKr,
        awayNameEn: game.awayTeam.nameEn,
        marketsId: folders.marketSeq,
        marketNameKr: folders.periodSequence !== '0' ? folders.market.nameKr?.replace('{sw}', folders.periodSequence) : folders.market.nameKr,
        marketNameEn: folders.periodSequence !== '0' ? folders.market.nameEn?.replace('{sw}', folders.periodSequence) : folders.market.nameEn,
        startTime: game.startTime,
        odds1: null,
        odds2: null,
        odds3: null,
        betType: '인플레이',
        detailType: null,
        currentPeriod: game.currentPeriod,
        currentTime: `${game.subResult}`,
        homeScore: game.homeResult,
        awayScore: game.awayResult,
        penalty: null,
        maxAmount: game.league.maxAmount,
      }

      if (cartItem.marketNameKr?.indexOf('{sw}') >= 0) {
        if (folders.periodSequence !== '0'){
          cartItem.marketNameKr = cartItem.marketNameKr.replace('{sw}', folders.periodSequence)
          cartItem.marketNameEn = cartItem.marketNameEn.replace('{sw}', folders.periodSequence)
        }
      }

      if (cartItem.marketNameKr?.indexOf('1팀') >= 0) {
        cartItem.marketNameKr = cartItem.marketNameKr.replace('1팀', cartItem.homeNameKr || cartItem.homeNameEn)
      }

      if (cartItem.marketNameKr?.indexOf('2팀') >= 0) {
        cartItem.marketNameKr = cartItem.marketNameKr.replace('2팀', cartItem.awayNameKr || cartItem.awayNameEn)
      }
      this.$store.dispatch('ADD_CART_INPLAY_ITEM', cartItem)
    },
    // 카트내역 체크해서 표시
    checkCart: function (bets, type) {
      if (type === '보너스' || !bets) {
        return null;
      } else {
        let index = this.cartItem.find(item => item.betsSeq === bets.seq)
        return !!index;
      }
    },

    selectGame: function(game){
      this.selectedGame = game;
    },
    uniq: function(array){
      return Array.from(new Set(array));
    },
    moveBack: function(){
      this.selectedGame = null;
    },
    filterByGames: function (sortType, target) {
      this.selectedGame = null;
      if (sortType === 'all') {
        return this.lists = this.originList;
      }
      if (sortType === 'league') {
        this.lists = this.originList.filter(e => e.leagueSeq === target);
      }

      if (sortType === 'date') {
        this.lists = this.originList.filter(e => e.startTime.indexOf(target) >= 0);
      }

      if (sortType === 'sport') {
        this.lists = this.originList.filter(e => e.sportSeq === target);
      }

      if (sortType === 'team') {
        this.lists = this.originList.filter(e =>
            e.homeTeam.nameEn.indexOf(target) >= 0 ||
            e.homeTeam.nameKr.indexOf(target) >= 0 ||
            e.awayTeam.nameEn.indexOf(target) >= 0 ||
            e.awayTeam.nameKr.indexOf(target) >= 0
        );
      }
    },
    toggleStick: function(){
      this.sticky = !this.sticky;
    },
    /* 서버 설정에 따라서 경기 베팅을 막는다.
    *  True
    * */
    checkBlockGame: function(game){
      let blockConfig = {};
      if (game.sportSeq === 210) {
        blockConfig = this.blockSetting['football'];
        if (game.subResult?.indexOf('HT') >= 0) return false;
        if (Number(game.currentPeriod) === 1 && 45 - Number(game.currentTime) <= Number(blockConfig.first)) {
          const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
          return true;
        }
        if (Number(game.currentPeriod) === 2 && 90 - Number(game.currentTime) <= Number(blockConfig.second)) {
          const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
          return true;
        }
      }
      if (game.sportSeq === 211) {
        blockConfig = this.blockSetting['hockey'];
        let remainTime = game.remainTime;
        const time = game.subResult?.split(' ');
        if (time) remainTime = `00:${time[time.length -1]}`;
        if (Number(game.currentPeriod) === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
          const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
          return true;
        }
        if (Number(game.currentPeriod) === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
          const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
          return true;
        }
        if (Number(game.currentPeriod) === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
          const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
          return true;
        }
      }
      if (game.sportSeq === 212) {
        blockConfig = this.blockSetting['basketball'];
        let remainTime = game.remainTime;
        const time = game.subResult?.split(' ');
        if (time) remainTime = `00:${time[time.length -1]}`;
        if (Number(game.currentPeriod) === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
          if (!remainTime) return true;
          const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
          return true;
        }
        if (Number(game.currentPeriod) === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
          if (!remainTime) return true;
          const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
          return true;
        }
        if (Number(game.currentPeriod) === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
          if (!remainTime) return true;
          const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
          return true;
        }
        if (Number(game.currentPeriod) >= 4) {
          const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
          return true;
        }
        if (Number(game.currentPeriod) === 4 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.four)}:00`) {
          if (!remainTime) return true;
          const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
          return true;
        }
      }
      if (game.sportSeq === 214) {
        blockConfig = this.blockSetting['volleyball'];
        const name = `set${game.currentPeriod}Score`;
        const setHomeScore = game[name] ? game[name].split(':')[0] : 0;
        const setAwayScore = game[name] ? game[name].split(':')[1] : 0;
        if (Number(game.currentPeriod) === 1){
          if (Number(setHomeScore) >= Number(blockConfig.first) - 1) {
            const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
            if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.first) - 1) {
            const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
            if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
            return true;
          }
        }

        if (Number(game.currentPeriod) === 2){
          if (Number(setHomeScore) >= Number(blockConfig.second) - 1) {
            const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
            if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.second) - 1) {
            const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
            if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
            return true;
          }
        }

        if (Number(game.currentPeriod) === 3){
          if (Number(setHomeScore) >= Number(blockConfig.third) - 1) {
            const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
            if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.third) - 1) {
            const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
            if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
            return true;
          }
        }

        if (Number(game.currentPeriod) === 4){
          if (Number(setHomeScore) >= Number(blockConfig.four) - 1) {
            const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
            if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.four) - 1) {
            const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
            if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
            return true;
          }
        }

        if (Number(game.currentPeriod) === 5){
          if (Number(setHomeScore) >= Number(blockConfig.five) - 1) {
            const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
            if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.five) - 1) {
            const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
            if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
            return true;
          }
        }

      }
      if (game.sportSeq === 215) {
        blockConfig = this.blockSetting['americanFootball'];
        let remainTime = game.remainTime;
        const time = game.subResult?.split(' ');
        if (time) remainTime = `00:${time[time.length -1]}`;
        if (Number(game.currentPeriod) === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
          const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
          return true;
        }
        if (Number(game.currentPeriod) === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
          const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
          return true;
        }
        if (Number(game.currentPeriod) === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
          const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
          return true;
        }
        if (Number(game.currentPeriod) === 4 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.four)}:00`) {
          const cartIndex = this.$store.state.sports.cart.findIndex(i => String(i.gameId) === String(game.id));
          if (cartIndex >= 0) this.$store.state.sports.cart.splice(cartIndex, 1);
          return true;
        }
      }

      return false;
    },
  },
}
</script>

<style scoped>
.event-detail-list-move {
  transition: transform 0.8s;
}
.event-detail-list {
  transition: transform 0.8s;
}
</style>
