<template>
  <div>
    <div class="main desktop">
      <h1>자유게시판</h1>
      <div class="post-info">
        <div class="title">
          {{ article.title }}
        </div>
        <div class="author-info">
          <img
            :src="getSiteLogo(article.sitename)"
            alt=""
            style="max-height: 15px; width: auto"
          />
          <span>작성자</span>
          <span>{{ article.author_nickname }}</span>
          <span class="date">
            작성일
            {{ article.regdatetime | formatDate("YYYY-MM-DD HH:mm:ss") }} 조회수
            {{ article.count_view }}
          </span>
        </div>
        <div class="post" v-html="renderHtml(article.content)"></div>

      </div>
      <div class="table-container">
        <table
          v-if="article.bettings.length"
          id="tableAll1"
          cellspacing="0"
          cellpadding="0"
          width="100%"
          class="dataTable no-footer"
          role="grid"
          style="width: 100%"
        >
          <thead>
            <tr role="row">
              <th class="sorting" style="width: 220px">베팅 NO</th>
              <th
                class="sorting_desc"
                style="width: 120px"
                aria-sort="descending"
              >
                베팅 일시
              </th>
              <th class="sorting_asc" style="width: 130px">베팅 종목</th>
              <th class="sorting" style="width: 130px">베팅 결과</th>
              <th class="sorting" style="width: 168px">배팅 금액</th>
              <th class="sorting" style="width: 143px">배당율</th>
              <th class="sorting" style="width: 144px">예상 적중 금액</th>
              <th class="sorting" style="width: 71px">폴더</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(bettings, index) in article.bettings">
              <tr
                id="tableall1_2802777"
                role="row"
                class="odd"
                :key="'bettingTitle' + index"
              >
                <td @click="toggle(index)">
                  {{ bettings[0].betting_type }}
                </td>
                <td @click="toggle(index)" class="sorting_1">
                  {{
                    bettings[0].betting_regdatetime
                      | formatDate("YYYY-MM-DD HH:mm:ss")
                  }}
                </td>
                <td @click="toggle(index)">
                  {{
                    bettings[0].betting_game_sports_name_kr ||
                    bettings[0].betting_game_sports_name_en
                  }}
                  {{ bettings.length > 1 ? "외" : "" }}
                </td>
                <td @click="toggle(index)">
                  <span
                    :class="{
                      'text-yellow':
                        bettings[0].betting_total_result === 'exception',
                      'text-red': bettings[0].betting_total_result === 'miss',
                      'text-green': bettings[0].betting_total_result === 'hit',
                      'text-gray': bettings[0].betting_total_result === 'wait',
                    }"
                    >{{ bettings[0].betting_bet_amount | makeComma }}</span
                  >
                </td>
                <td @click="toggle(index)">
                  <span
                    :class="{
                      'text-yellow':
                        bettings[0].betting_total_result === 'exception',
                      'text-red': bettings[0].betting_total_result === 'miss',
                      'text-green': bettings[0].betting_total_result === 'hit',
                      'text-gray': bettings[0].betting_total_result === 'wait',
                    }"
                    >{{ Number(bettings[0].betting_total_odds).toFixed(2) }}
                  </span>
                </td>
                <td @click="toggle(index)">
                  <span
                    :class="{
                      'text-yellow':
                        bettings[0].betting_total_result === 'exception',
                      'text-red': bettings[0].betting_total_result === 'miss',
                      'text-green': bettings[0].betting_total_result === 'hit',
                      'text-gray': bettings[0].betting_total_result === 'wait',
                    }"
                    >{{ bettings[0].betting_expected_prize | makeComma }}</span
                  >
                </td>
                <td @click="toggle(index)">
                  {{ bettings.length > 1 ? `${bettings.length}폴더` : "단폴더" }}
                </td>
              </tr>
              <transition name="fade" :key="'bettingDetail' + index">
                <tr>
                  <td colspan="9">
                    <div class="sub_table">
                      <table
                        id="sub_tableall1_2802725"
                        class="panel-body bet_dd_p"
                        cellpadding="0"
                        cellspacing="0"
                        :class="{
                          acc_list_table_in_01:
                            bettings[0].betting_total_result === 'exception',
                          acc_list_table_in_02:
                            bettings[0].betting_total_result === 'miss',
                          acc_list_table_in_04:
                            bettings[0].betting_total_result === 'hit',
                          acc_list_table_in_03:
                            bettings[0].betting_total_result === 'wait',
                        }"
                      >
                        <tbody>
                          <tr>
                            <td class="acc_list_table_in_t" width="11%">
                              경기일시
                            </td>
                            <td class="acc_list_table_in_t" width="8%">종목</td>
                            <td class="acc_list_table_in_t" width="15%">
                              리그
                            </td>
                            <td class="acc_list_table_in_t" width="18%">팀</td>
                            <td class="acc_list_table_in_t" width="10%">
                              타입
                            </td>
                            <td class="acc_list_table_in_t" width="14%">
                              배팅
                            </td>
                            <td class="acc_list_table_in_t" width="6%">
                              배당율
                            </td>
                            <td class="acc_list_table_in_t" width="6%">결과</td>
                          </tr>
                          <tr
                            v-for="(row2, index2) in bettings"
                            :key="'bets' + row2.bets_id + index2"
                          >
                            <td class="bet_time">
                              {{
                                row2.betting_game_starttime
                                  | formatDate("YYYY-MM-DD HH:mm:ss")
                              }}
                            </td>
                            <td class="bet_event">
                              {{
                                row2.betting_game_sports_name_kr ||
                                row2.betting_game_leagues_name_en
                              }}
                            </td>
                            <td class="bet_name">
                              {{
                                row2.betting_game_leagues_name_kr ||
                                row2.betting_game_leagues_name_en
                              }}
                            </td>
                            <td class="bet_name">
                              {{
                                row2.betting_game_home_name_kr ||
                                row2.betting_game_home_name_en
                              }}
                              -
                              {{
                                row2.betting_game_away_name_kr ||
                                row2.betting_game_away_name_en
                              }}
                            </td>
                            <td class="bet_type">
                              {{
                                row2.betting_game_markets_name_kr ||
                                row2.betting_game_markets_name_en
                              }}
                              <span v-if="row2.betting_round">
                                ({{ row2.betting_round }})
                              </span>
                            </td>
                            <td class="bet_type">
                              {{ row2 | betsName2 }}
                            </td>
                            <td class="bet_type">
                              {{
                                bettings.length === 1
                                  ? (
                                      Number(bettings[0].betting_odds) +
                                      Number(bettings[0].betting_total_odds_penalty)
                                    ).toFixed(2)
                                  : row2.betting_odds
                              }}
                            </td>
                            <td
                              class="beting_in_btn"
                              v-if="row2.betting_status.indexOf('취소') < 0"
                            >
                              <span
                                :class="{
                                  'text-yellow':
                                    row2.betting_result === 'exception',
                                  'text-red': row2.betting_result === 'miss',
                                  'text-green': row2.betting_result === 'hit',
                                  'text-gray': row2.betting_result === 'wait',
                                }"
                              >
                                {{ row2.betting_result | bettingStatus }}</span
                              >
                            </td>
                            <td v-else>취소</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </transition>
            </template>
          </tbody>
        </table>
      </div>
      <div class="buttons">
        <div class="left-buttons">
          <template v-if="isAuthor">
            <button @click="navigateToEdit">수정하기</button>
            <button>삭제하기</button>
          </template>
        </div>
        <div class="right-button">
          <button @click="movePage('bbs/f/l')">목록으로</button>
        </div>
      </div>
      <div class="comment-section">
        <input
          v-model="newComment"
          placeholder="댓글을 입력하세요"
          type="text"
        />
        <button @click="submitComment">댓글 등록</button>
      </div>
      <div class="comments">
        <div v-if="article.comments.length">
          <div
            class="comment"
            v-for="comment in article.comments"
            :key="comment.seq"
          >
            <div class="user">
              <img
                :src="getSiteLogo(comment.sitename)"
                alt=""
                style="max-height: 15px; width: auto"
              />
              <span>{{ comment.author_nickname }}</span>
              <span>{{ comment.content }}</span>
            </div>
            <div class="date">{{ comment.regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}</div>
          </div>
        </div>
        <div v-else>
          <p>아직 댓글이 없습니다.</p>
        </div>
      </div>
    </div>
    <div class="content mobile" style="min-height: 800px">
      <h1>자유게시판</h1>
      <div class="table-container">
        <div class="notice">
          <div class="title" style="color: white;">
            <span v-if="article.category === 2" style="color:#ffcc00; border: 1px solid #ffcc00; padding: 4px; border-radius: 2px; margin-right: 5px;">{{ article.category | categorize }}</span>
            <span v-if="article.category === 3" style="color:#ff0000; border: 1px solid #ff0000; padding: 4px; border-radius: 2px; margin-right: 5px;">{{ article.category | categorize }}</span>
              {{ article.title }}
          </div>
          <div class="description">
            <img :src="getSiteLogo(article.sitename)" alt="" style="max-height: 15px; width: auto;">
            <span class="author">{{ article.author_nickname }}</span>
            <span class="createdAt">{{ article.regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}</span>
            <span class="count" style="color: #a97bf5;">{{ article.count_view }}</span>
          </div>
        </div>
        <!-- <div class="freeboard">
          <div v-for="(item, index) in list" :key="'notice'+index">
            <div class="title" style="color: white;">
              <span style="color:white; border: 1px solid white; padding: 4px; border-radius: 2px; margin-right: 5px;">{{ item.category | categorize }}</span>
              {{ item.title }}
              <span class="badge" v-if="item.is_betting_attached === 'y'">BET</span>
              <span v-if="item.count_comment > 0" style="color:#9a9aff">({{ item.count_comment }})</span>
            </div>
            <div class="description">
              <span class="type">
                <img :src="getSiteLogo(item.sitename)" alt="" style="max-height: 15px; width: auto;">
              </span>
              <span class="author">{{ item.author_nickname }}</span>
              <span class="createdAt">{{ item.regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}</span>
              <span class="count" style="color: #a97bf5;">{{ item.count_view }}</span>
            </div>
          </div>
        </div> -->
        <div class="post" v-html="renderHtml(article.content)"></div>
        <template v-for="bettings in article.bettings">
          <div
              class="m_betting_div"
              :key="bettings[0].betting_seq"
              v-if="article.bettings.length > 0"
          >
            <div
                class="total_betting_div"
                :class="{
                  'bg-y': bettings[0].betting_total_result === 'exception',
                  'bg-r': bettings[0].betting_total_result === 'miss',
                  'bg-g': bettings[0].betting_total_result === 'hit',
                  'bg-gr': bettings[0].betting_total_result === 'wait',
                }"
            >
              <table border="0" width="100%">
                <tr>
                  <td class="text-gray">
                    일시 :
                    <span>{{ bettings[0].betting_regdatetime | formatDate("YY-MM-DD HH:mm:ss") }}</span>
                    <span v-if="bettings[0].betting_total_result === 'hit' && bettings[0].betting_payed === 'n'" class="text-green">&nbsp;&nbsp;지급대기</span>
                  </td>
                </tr>
                <tr>
                  <td style="color: rgb(153, 153, 153)">
                    <span>
                      {{ bettings[0].betting_type }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    폴더
                    <span class="orange--text">{{ bettings.length }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    베팅금액
                    <span class="orange--text">{{
                        bettings[0].betting_bet_amount | makeComma
                      }}</span
                    >원
                  </td>
                </tr>
                <tr>
                  <td>
                    베당율
                    <span class="orange--text">{{Number(bettings[0].betting_total_odds) .toFixed(2) }}</span>배
                  </td>
                </tr>
                <tr>
                  <td>
                    (예상)적중금액
                    <span class="orange--text">{{ bettings[0].betting_expected_prize | makeComma }}</span
                    >원
                  </td>
                </tr>
              </table>
            </div>
            <transition name="fade">
              <div>
                <div
                    class="sub_betting_div"
                    :class="{
                        'border-y': row2.betting_result === 'exception',
                        'border-r': row2.betting_result === 'miss',
                        'border-g': row2.betting_result === 'hit',
                        'border-gr': row2.betting_result === 'wait',
                      }"
                    v-for="(row2, index2) in bettings"
                    :key="'bettingDetail' + index2 + row2.betting_folder"
                >
                  <table
                      border="0"
                      width="100%"
                      style="color: white; font-size: 14px; padding-left: 10px"
                  >
                    <tr class="text-gray">
                      <td colspan="2" class="pt-2 text-gray">
                        {{
                          row2.betting_game_starttime | formatDate("YY-MM-DD HH:mm")
                        }}
                        |
                        {{
                          row2.betting_game_leagues_name_kr ||
                          row2.betting_game_leagues_name_en
                        }}
                      </td>
                    </tr>
                    <tr class="text-gray">
                      <td class="text-gray">게임아이디: {{ row2.betting_game }}</td>
                      <td
                          class="text-gray text-right pr-3"
                          style="text-align: right; line-height: 1.4"
                      >
                        {{
                          bettings.length === 1
                              ? (
                                  Number(bettings[0].betting_odds) +
                                  Number(bettings[0].betting_total_odds_penalty)
                              ).toFixed(2)
                              : row2.betting_odds
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td style="color: white">
                        {{
                          row2.betting_game_home_name_kr ||
                          row2.betting_game_home_name_en
                        }}
                        <span class="grey--text text-gray"> vs </span>
                        {{
                          row2.betting_game_away_name_kr ||
                          row2.betting_game_away_name_en
                        }}
                      </td>
                      <td
                          class="text-right pr-3"
                          v-if="row2.betting_status.indexOf('취소') < 0"
                          style="text-align: right; line-height: 1.4;">
                        <span v-if="row2.betting_result == 'hit'" style="color: #44d344">{{ row2.betting_result | bettingStatus }}</span>
                        <span v-else style="color: white">{{ row2.betting_result | bettingStatus }}</span>
                      </td>
                      <td
                          class="text-right pr-3"
                          style="text-align: right; line-height: 1.4; color: white"
                          v-else
                      >
                        취소
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="pb-2" style="color: white">
                        <span style="color: deepskyblue">{{
                            row2.betting_game_markets_name_kr ||
                            row2.betting_game_markets_name_en
                          }}
                        </span>
                        <span v-if="row2.betting_round"
                        >( {{ row2.betting_round }} )</span
                        >
                        / {{ row2 | betsName2 }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </transition>
          </div>
        </template>

        <div class="buttons">
          <div class="left-buttons">
            <template v-if="isAuthor">
              <button @click="navigateToEdit">수정하기</button>
              <button>삭제하기</button>
            </template>
          </div>
          <div class="right-button">
            <button @click="movePage('bbs/f/l')">목록으로</button>
          </div>
        </div>
        <div class="comment-section">
          <input
            v-model="newComment"
            placeholder="댓글을 입력하세요"
            type="text"
          />
          <button @click="submitComment">댓글 등록</button>
        </div>
        <div class="comments">
          <div v-if="article.comments.length">
            <div
              class="comment"
              v-for="comment in article.comments"
              :key="comment.seq"
            >
              <div class="user">
                <img
                  :src="getSiteLogo(comment.sitename)"
                  alt=""
                  style="max-height: 15px; width: auto"
                />
                <span>{{ comment.author_nickname }}</span>
                <hr>
                <span>{{ comment.content }}</span>
              </div>
              <div class="date">{{ comment.regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}</div>
            </div>
          </div>
          <div v-else>
            <p>아직 댓글이 없습니다.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MSG from "@/contants/msg";
import {mapState} from "vuex";

export default {
  name: "Detail",
  data: function () {
    return {
      path: "/freeboard/detail/" + this.$route.params.seq,
      article: null,
      opened: [],
      newComment: "",
    };
  },
  mounted: function () {
    this.reqData();
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      isMobile: state => state.page.isMobile,
      version: state => state.version.version,
    }),
    isAuthor() {
      return this.article.author === this.user.members_seq;
    },
  },
  methods: {
    reqData: function () {
      return this.$store
        .dispatch("GET_FREE_DETAIL", {
          path: this.path,
        })
        .then((data) => {
          const result = data.data;
          this.article = result.payload.article;
        })
        .catch((err) => {
          console.log("에러내용!!!", err.response.data);
          if (err.response.data.status === 401) {
            this.$store.dispatch("SHOW_ERR_MSG", MSG.error.authorization);
            return this.$router.push({ path: "/bbs/f/l" });
          }
        });
    },
    navigateToEdit() {
      if (!this.article) {
        alert("수정할 데이터를 불러오지 못했습니다.");
        return;
      }
      this.$router.push({
        name: "자유게시판-수정하기",
        params: { article: this.article }, // 데이터 전달
      });
    },
    movePage: function (page) {
      this.$router.push({ path: `/${page}` });
    },
    renderHtml: function (str) {
      if (!str) return "";
      return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
    submitComment: function () {
      if (!this.newComment.trim()) {
        alert("댓글 내용을 입력해주세요.");
        return;
      }

      const path = `/freeboard/comment/${this.$route.params.seq}`;
      const data = {
        payload: { content: this.newComment },
      };

      this.$store
        .dispatch("WRITE_COMMENT", { path, data })
        .then((response) => {
          console.log(response);
          alert("댓글이 등록되었습니다.");
          this.newComment = "";
          this.reqData(); // 댓글 등록 후 다시 데이터 로드
        })
        .catch((err) => {
          console.log("댓글 등록 실패", err.response.data);
        });
    },
    getSiteLogo(sitename) {
      const imageMap = {
        viva: require("@/assets/img/main/kiki_logo_main.png"),
        kiki: require("@/assets/img/main/kiki_logo_main.png"),
        vikbet: require("@/assets/img/main/vikbet_logo_main.png"),
        pumpkin: require("@/assets/img/main/vikbet_logo_main.png"),
        luna: require("@/assets/img/main/luna_logo_main.png"),
        viking: require("@/assets/img/main/kai_logo_main.png"),
        kai: require("@/assets/img/main/kai_logo_main.png"),
      };
      return (
        imageMap[sitename] || require("@/assets/img/main/kai_logo_main.png")
      );
    },
    toggle: function (id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },

  },
};
</script>

<style scoped>
@media all and (min-width: 600px) {
  .mobile {
    display: none;
  }
  .main {
    padding: 20px;
  }

  .main h1 {
    color: #a97bf5;
    text-align: center;
    margin-bottom: 20px;
  }

  .post-info {
    background-color: #2a2a2a;
    /* padding: 10px; */
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .post-info .title {
    font-size: 18px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #3a3a3a;
    /* padding: 5px; */
    border-radius: 3px;
    border-top: 2px solid #a97bf5;
    border-bottom: 1px solid black;
  }

  .post-info .author-info {
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
    font-size: 15px;
    color: white;
    border-bottom: 1px solid black;
  }

  .post-info .author-info img {
    width: 20px;
    height: 20px;
  }

  /* .post-info .author-info span {
    color: #ff6666;
  } */

  .post-info .post {
    padding: 25px;
    margin-bottom: 10px;
    font-size: 13px;
  }

  .post-info .date {
    text-align: right;
    color: #999;
  }

  .table-container {
    overflow-x: auto;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  table th,
  table td {
    border: 1px solid #444;
    padding: 10px;
    text-align: center;
  }

  table th {
    background-color: #333;
    color: #fff;
  }

  table td {
    background-color: #2a2a2a;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    background-color: #2a2a2a;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .buttons .left-buttons {
    display: flex;
    gap: 10px;
  }

  .buttons .right-button {
    display: flex;
    justify-content: flex-end;
  }

  .buttons button {
    background-color: #a97bf5;
    border: none;
    padding: 10px 20px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
  }

  .buttons button:hover {
    background-color: #a97bf5;
  }

  .comment-section {
    background-color: #2a2a2a;
    padding: 10px;
    border-radius: 5px;
  }

  .comment-section input[type="text"] {
    width: calc(100% - 120px);
    padding: 10px;
    border: 1px solid #444;
    background-color: #1a1a1a;
    color: #ccc;
    border-radius: 5px;
  }

  .comment-section button {
    background-color: #a97bf5;
    border: none;
    padding: 10px 20px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    height: 46px;
    margin-left: 5px;
  }

  .comment-section button:hover {
    background-color: #a97bf5;
  }

  .comments {
    /* margin-top: 20px; */
    padding: 10px;
  }

  .comment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #444;
  }

  .comment .user {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .comment .user img {
    width: 20px;
    height: 20px;
  }

  /* .comment .user span {
    color: #ff6666;
  } */

  .comment .date {
    color: #999;
  }
}
@media all and (max-width: 600px) {
  .desktop {
    display: none;
  }
  .content {
    padding: 5px;
  }

  .table-container table {
    width: 100%;
  }

  .description {
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }

  .content h1 {
    color: #a97bf5;
    border-bottom: 1px solid #a97bf5;
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-align: center;
  }

  .freeboard {
    border-top: 1px solid #a97bf5;
    padding-top: 10px;
  }

  .post {
    /* background-color: #2b2b2b; */
    padding: 10px;
    margin-bottom: 10px;
    border-top: 3px solid black;
  }

  .post.event {
    border-color: #d4ff00;
  }

  .post.notice {
    border-color: #ff0000;
  }

  .post.general {
    border-color: #ffffff;
  }

  .post .title {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .post .title .label {
    background-color: #d4ff00;
    color: #000000;
    padding: 2px 5px;
    border-radius: 3px;
    margin-right: 10px;
  }

  .post.notice .title .label {
    background-color: #ff0000;
  }

  .post.general .title .label {
    background-color: #ffffff;
    color: #000000;
  }

  .post .title .text {
    flex-grow: 1;
  }

  .post .details {
    display: flex;
    justify-content: space-between;
    font-size: 8px;
    color: #aaaaaa;
  }

  .post .details .author {
    display: flex;
    align-items: center;
  }

  .post .details .author img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .post .details .info {
    display: flex;
    align-items: center;
  }

  .post .details .info i {
    margin-right: 5px;
  }


  .buttons {
    display: flex;
    justify-content: space-between;
    background-color: #2a2a2a;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .buttons .left-buttons {
    display: flex;
    gap: 10px;
  }

  .buttons .right-button {
    display: flex;
    justify-content: flex-end;
  }

  .buttons button {
    background-color: #a97bf5;
    border: none;
    padding: 10px 20px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
  }

  .event_text {
    color: #ffcc00
  }

  .notice_text {
    color: #ff0000
  }

  
  .comment-section {
    background-color: #2a2a2a;
    padding: 10px;
    border-radius: 5px;
  }

  .comment-section input[type="text"] {
    width: calc(100% - 120px);
    padding: 10px;
    border: 1px solid #444;
    background-color: #1a1a1a;
    color: #ccc;
    border-radius: 5px;
  }

  .comment-section button {
    background-color: #a97bf5;
    border: none;
    padding: 10px 20px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
  }

  .comment-section button:hover {
    background-color: #a97bf5;
  }

  .comments {
    margin-top: 20px;
    padding: 10px;
  }

  .comment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #444;
  }

  .comment .user {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .comment .user img {
    width: 20px;
    height: 20px;
  }

  /* .comment .user span {
    color: #ff6666;
  } */

  .comment .date {
    color: #999;
  }
} 
</style>
