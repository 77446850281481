<template>
  <div class="m-game3">
    <!-- <div class="title" style="margin: 5px 0px;">
      <div class="left">
        <div class="subj type1">보너스배당</div>
      </div>
    </div> -->
    <!-- <div class="game-item" v-for="(rowB, indexBonus) in bonus" :key="'bonusIndex'+indexBonus">
      <dl @click="addBonus(rowB)" :class="{'active': checkCart(rowB, '보너스')}">
        <dt>{{ rowB.name }}이상 보너스</dt>
        <dd><span>{{ rowB.rate }}</span></dd>
      </dl>
      <div class="vs">VS</div>
      <dl>
        <dt>{{ rowB.rate }} 배당</dt>
        <dd><span>{{ rowB.rate }}</span></dd>
      </dl>
    </div> -->
    <template v-for="date in this.uniqGameStartTime">
      <template v-for="(league, leagueIdx) in timeLeagueSort(date)">
        <div class="title" style="margin: 5px 0 5px 0;" :key="'leagueTitle' + $options.filters.randomNum(leagueIdx)">
          <div class="left">
            <div class="subj">
              <img
                  v-if="lists.find(e => e.leagueId === league).sport.icon"
                  :src="lists.find(e => e.leagueId === league).sport.icon"
                  style="width: 20px;" class="ic1">
              <img
                  v-if="lists.find(e => e.leagueId === league).league.icon"
                  :src="lists.find(e => e.leagueId === league).league.icon"
                  style="margin-left: 6px;"
                  class="ic2">
              {{ lists.find(e => e.leagueId === league).location | locationName }} - {{ lists.find(e => e.leagueId === league).league | leagueName }}
            </div>
          </div>
          <div class="right">{{ date | formatDate('MM-DD') }}&nbsp;{{ date | minute }}</div>
        </div>
        <template v-for="game in gameTimeLeagueSort(date, league)">
          <template v-if="game.isVisible === 'y'">
            <div class="game-item"
                 v-for="(folder, index) in game.folders.filter(f => f.isSuspended === 'n')"
                 :key="'gameSeq'+ game.id +index"
                 v-if="index===0 || checkArray(game.id)"
            >
              <template v-if="game.isSuspended === 'y'">
                <dl  class="">
                  <dt style="width: 60%;"> {{ game.homeTeam | teamName }} </dt>
                  <dd>
                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                  </dd>
                </dl>
                <div class="vs" style="padding:2px;">
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </div>
                <dl  class="" >
                  <dt style="width: 60%;"> {{ game.awayTeam | teamName }} </dt>
                  <dd>
                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                  </dd>
                </dl>
                <button class="btn-val"
                        style="background: #A97BF5 !important;"
                >
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </button>
              </template>
              <template v-else>
                <template v-if="folder.isVisible === 'y' && folder.isSuspended === 'y'">
                  <dl  class="">
                    <dt style="width: 60%;"> {{ game.homeTeam | teamName }} </dt>
                    <dd>
                      <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                    </dd>
                  </dl>
                  <div class="vs" v-if="folder.line">
                    {{ folder.market.nameKr.includes('오버언더')  ? folder.line : folder.market.nameKr.includes('핸디캡') ? folder.line.split(' ')[0] : 'VS'  }}
                  </div>
                  <div class="vs" style="padding: 2px !important;" v-else>
                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                  </div>
                  <dl  class="" >
                    <dt style="width: 60%;"> {{ game.awayTeam | teamName }} </dt>
                    <dd>
                      <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                    </dd>
                  </dl>
                  <button class="btn-val"
                          v-if="index === 0"
                          v-on:click="toggleDetail(game.id)">+ {{ game.folders.length }}
                  </button>
                  <button class="btn-bet" v-else>배팅</button>
                </template>
                <template v-else-if="folder.isVisible === 'y' && folder.isSuspended === 'n'">
                  <MKoreanPrice
                      :bets="folder.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === 'Home' || e.name === 'Yes')"
                      :folder="folder"
                      :add-cart="addCart"
                      :check-cart="checkCart"
                      :game="game"
                      :team="game.homeTeam"
                  ></MKoreanPrice>
                  <div v-if="!folder.bets.find(e => e.name === 'X')" class="vs">
                    {{ folder.market.nameKr.includes('오버언더')  ? folder.line : folder.market.nameKr.includes('핸디캡') ? folder.line.split(' ')[0] : 'VS'  }}
                  </div>
                  <div class="vs"
                       v-else-if="folder.bets.find(e => e.name === 'X').price && folder.isSuspended === 'n'"
                       :class="{'active': checkCart(folder.bets.find(e => e.name === 'X'))}"
                       @click="addCart(folder.bets.find(e => e.name === 'X'), folder, game)"
                  >
                    {{ Number(folder.bets.find(e => e.name === 'X').price).toFixed(2)  }}
                  </div>
                  <div class="vs" v-else>
                    <img style="width: 9px; margin-top:-3px;" src="@/assets/img/ico/ico_secret.png">
                  </div>
                  <MKoreanPrice
                      :bets="folder.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'Away' || e.name === 'No')"
                      :folder="folder"
                      :add-cart="addCart"
                      :check-cart="checkCart"
                      :game="game"
                      :team="game.awayTeam"
                  ></MKoreanPrice>
                  <button class="btn-val"
                          v-if="index === 0"
                          v-on:click="toggleDetail(game.id)">+ {{ game.folders.filter(e => e.isVisible === 'y').length }}
                  </button>
                  <button v-else class="btn-bet">배팅</button>
                </template>
              </template>
            </div>
          </template>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import MKoreanPrice from "./MWhitePrice";

export default {
  name: "MFolderWhiteWrapper",
  props: ['lists', 'checkCart', 'addCart', 'addBonus', 'bonus'],
  components: {
    MKoreanPrice
  },
  computed: {
    uniqGameStartTime: function(){
      const dates = this.lists.map(e => e.startTime);
      const time = this.$options.filters.uniq(dates)
      return time;
    }
  },
  data: function () {
    return {
      openDetailBet: [],
    }
  },
  created() {
    this.sortLeagues()
  },
  methods: {
    /*
    * 종목의 자세히 버튼
    */
    checkArray: function (id) {
      let result = this.openDetailBet.indexOf(id) >= 0
      return result
    },
    /*
     * 토글 액션
     */
    toggleDetail: function (id) {
      this.toggle(this.openDetailBet, id)
    },
    removeAtIndex: function (arr, index) {
      // const copy = [...arr];
      this.openDetailBet.splice(index, 1);
      // return this.openDetailBet;
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailBet = [...arr, item]
        return;
      }
      return this.removeAtIndex(arr, index);
    },
    timeLeagueSort: function(date){
      const games = this.lists.filter(e => e.startTime === date);
      const leagues = games.map(e => e.leagueId);
      const leaguesId = this.$options.filters.uniq(leagues);
      return leaguesId;
    },
    sortLeagues: function(){
      for(const u in this.uniqGameStartTime){
        this.timeLeagueSort(this.uniqGameStartTime[u]);
      }
    },
    gameTimeLeagueSort: function(date, league){
      const games = this.lists.filter(e => e.startTime === date && e.leagueId === league);
      return games;
    }
  }
}
</script>

<style scoped>
.vs img {
  margin-top: -3px !important;
}
</style>
