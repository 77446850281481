<template>
    <td width="10%" class="game_right_wrap " :class="{'fixed': !sticky, 'stick': sticky}" valign="top">
        <div class="betting-slip">
                <div class="header">
                    <p class="tooltip" style="cursor:pointer; position:absolute; left:18px;" >
                        <img src="@/assets/img/ico/pinoff.png"  @click="sticky = !sticky;" @mouseover="upHere = true" @mouseleave="upHere = false">
                        <span class="tooltiptext2" style="display: block; z-index: 20000;"  v-if="upHere">
                            클릭하시면 베팅슬립이 <font class="font_002">고정</font>되어<br> 스크롤에 따라 <font class="font_002">움직이지 않습니다.</font>
                        </span>
                    </p> 
                         베팅슬립
                </div>
                <div class="content">
                        <div class="toggle-container">
                            <span style="margin-left: 104px; color:white;">배당변경 자동적용</span>
                            <div class="toggle">
                                        <input type="checkbox" id="auto-apply" v-model="autoModifyOdds" @click="autoModifyOdds = !autoModifyOdds">
                                        <label for="auto-apply"></label>
                                </div>
                        </div>

                        <div class="summary">
                                <p class="total-odds">총 배당률 <span>0</span></p>
                                <!-- <p >베팅금액 <span class="bet-amount">0</span></p> -->
                                <p >베팅금액 <input type="text" class="bet-amount" disabled value="0"></p>
                                <p>예상당첨금액 <span class="expected-amount">0원</span></p>
                        </div>
                        <button class="bet-button" @click="alert('로그인후 이용해주세요')">베팅하기</button>
                </div>
        </div>
    </td>
</template>

<script>
export default {
  name: "MLsportNewCartNotLogin",
  components: {
    // Loading
  },
  data: function(){
    return {
    sticky: false,
      processing: false,
      autoModifyOdds: true,
      isActiveFreeBet: false,
      selectedFreeBet: null,
      winnings: 0,
      amount: 0,
      maxOddsAmount: 0,
      limitWinnings: 0,
      maxAmount: 0,
      upHere: false,
    }
  },
}
</script>

<style scoped>
.fixed {
  position: fixed;
}

.stick {
  position: absolute;
}

.betting-slip {
    width: 300px;
    background-color: #1c1c1c;
    /* border-radius: 10px; */
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.header {
    background-color: #a97bf5;
    padding: 20px;
    text-align: center;
    font-size: 24px;
}
/* .header i {
    margin-right: 10px;
} */
.content {
    flex: 1;
    /* overflow-y: auto; */
}
.toggle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #262a2b;
    padding: 10px;
    border-bottom: 1px solid #000;
    /* border-radius: 5px; */
}
.toggle-container span {
    font-size: 14px;
}
.toggle-container .toggle {
    display: flex;
    align-items: center;
}
.toggle-container .toggle input {
    display: none;
}
.toggle-container .toggle label {
    background-color: #555;
    border-radius: 15px;
    cursor: pointer;
    height: 20px;
    position: relative;
    width: 40px;
}
.toggle-container .toggle label::after {
    background-color: #ccc;
    border-radius: 50%;
    content: '';
    height: 16px;
    left: 2px;
    position: absolute;
    top: 2px;
    transition: 0.3s;
    width: 16px;
}
.toggle-container .toggle input:checked + label {
    background-color: #a97bf5;
}
.toggle-container .toggle input:checked + label::after {
    left: 22px;
}
.bet-item {
    background-color: #262a2b;
    /* border-radius: 5px; */
    padding: 10px;
    position: relative;
    /* border-top: 1px solid #444; */
    border-bottom: 1px solid #444;
}
.bet-item .icon {
    font-size: 24px;
    margin-right: 10px;
}
.bet-item .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.bet-item .details .info {
    flex: 1;
}
.bet-item .details .info p {
    margin: 5px 0;
    color: white;
}
.bet-item .details .info .odds {
    background-color: #a97bf5;
    border-radius: 5px;
    color: white;
    display: inline-block;
    padding: 2px 5px;
}
.bet-item .details .date-time {
    text-align: right;
    color: white;
}
.bet-item .delete {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.freebet {
    background-color: #000;
    /* border-radius: 5px; */
    color: white;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 10px;
}
.active {
  background-color: #a97bf5 !important;
  color: black !important;
}

.freebet i {
    margin-right: 10px;
    color:#a97bf5;
}
.active i {
  color: #000 !important;
}

.freebet .toggle {
    margin-left: 182px;
    display: flex;
    align-items: center;
}
.freebet .toggle input {
    display: none;
}
.freebet .toggle label {
    background-color: #555;
    border-radius: 15px;
    cursor: pointer;
    height: 20px;
    position: relative;
    width: 40px;
}
.freebet .toggle label::after {
    background-color: #ccc;
    border-radius: 50%;
    content: '';
    height: 16px;
    left: 2px;
    position: absolute;
    top: 2px;
    transition: 0.3s;
    width: 16px;
}
/* .freebet .toggle input:checked + label {
    background-color: #a97bf5;
} */
.freebet .toggle input:checked + label::after {
    left: 22px;
}
.freebet-select {
    /* background-color: #a97bf5; */
    /* border-radius: 5px; */
    /* color: black; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #262a2b;
    padding: 10px;
}
.freebet-amount {
    background-color: #262a2b;
    /* border: none; */
    border-radius: 3px;
    color: white;
    /* padding: 10px; */
    width: 100%;
    /* text-align: center; */
    /* margin: 10px; */
    height: 30px;
}
.league-name{
  margin-top: -2px;
  color: white;
}
.summary {
    background-color: #262a2b;
    /* border-radius: 5px; */
    padding: 10px;
    /* margin: 10px; */
}
.summary p {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}
.summary .total-odds {
    font-size: 12px;
    color: white;
}
.summary .bet-amount {
    background-color: black;
    border-radius: 5px;
    padding: 5px;
    text-align: right;
    color: white;
    height: 12px;
}
.betting-amount-name {
  margin-top: 6px;
  color: white;
}
.winning-amount-name {
  margin-top: 7px;
  color: white;
}
.summary .expected-amount {
    /* background-color: black; */
    /* border-radius: 5px; */
    padding: 5px;
    text-align: right;
    color: white;
}
.bet-button {
    background-color: #a97bf5;
    border: none;
    /* border-radius: 5px; */
    color: white;
    font-size: 18px;
    padding: 15px;
    text-align: center;
    width: 100%;
    /* margin: 10px; */
    cursor: pointer;
}
.amounts {
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
    /* margin-bottom: 10px; */
    border-top: 1px solid #444;
    padding: 10px;
    background-color: #262a2b;
    gap: 3px;
}
.amounts .amount {
    background-color: rgb(47, 0, 47);
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 16%;
    color: white;
    text-align: center;
    font-size: 9px;
}

.buttons {
    display: flex;
    /* justify-content: space-between; */
    gap: 10px;
    width: 100%;
    padding: 0 10px 10px 10px;
    /* margin-bottom: 10px; */
    background-color: #262a2b;
}

.buttons .button {
    border: 2px solid #a97bf5;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 37%;
    text-align: center;
    background-color: transparent;
    color: #ffffff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

</style>
