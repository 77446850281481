<template>
    <tbody>
      <tr v-if="originList">
        <td colspan="10">
          <table class="ng-isolate-scope" style="width: 100%">
            <tbody>
              <tr>
                <td style="width: 100%">
                  <div class="ng-scope"
                     :class="{
                    'soccer_wrap': game.sportId === 1,
                    'hockey_wrap': game.sportId === 2,
                    'basketball_wrap': game.sportId === 3,
                    'tennis_wrap': game.sportId === 4,
                    'volleyball_wrap': game.sportId === 5,
                    'football_wrap': game.sportId === 6,
                    'baseball_wrap': game.sportId === 11,
                    'e_sport_wrap': game.sportId === 77,
                   }"
                  >
                      <div class="ng-scope-gamebox">
                            <div class="game_title">
                                <p>{{ game.league | leagueName }}</p>
                                <span>{{ game | currentPeriod3 }}</span>
                            </div>

                            <div class="game_score_newbox">
                                <div>
                                    <p>{{ game.homeTeam | teamName }}</p>
                                    <p>{{ game.awayTeam | teamName }}</p>
                                </div>
                                <table>
                                    <thead>
                                        <tr>
                                            <template v-if="game.sportId == 11">
                                              <template v-for="l in Number(9)">
                                                <th :key="l+'titlePeriod'+game.id">
                                                  {{ l | periodDetail(game.sportId) }}
                                                </th>
                                              </template>
                                            </template>
                                            <template v-else-if="game.sportId == 3">
                                               <template v-for="l in Number(4)">
                                                <th :key="l+'titlePeriod'+game.id">
                                                  {{ l | periodDetail(game.sportId) }}
                                                </th>
                                              </template>
                                            </template>
                                            <template v-else>
                                               <template v-for="l in Number(game.currentPeriod)">
                                                <th :key="l+'titlePeriod'+game.id">
                                                  {{ l | periodDetail(game.sportId) }}
                                                </th>
                                              </template>
                                            </template>
                                            <th>점수</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <template v-if="game.sportId == 11">
                                              <template v-for="l in Number(9)">
                                                <td :key="l+'titlePeriod'+game.id">
                                                  {{ game[`set${l}Score`] ? game[`set${l}Score`].split(':')[0] : '' }}
                                                </td>
                                              </template>
                                            </template>
                                            <template v-else-if="game.sportId == 3">
                                               <template v-for="l in Number(4)">
                                                <td :key="l+'titlePeriod'+game.id">
                                                  {{ game[`set${l}Score`] ? game[`set${l}Score`].split(':')[0] : '' }}
                                                </td>
                                              </template>
                                            </template>
                                            <template v-else>
                                               <template v-for="l in Number(game.currentPeriod)">
                                                <td :key="l+'titlePeriod'+game.id">
                                                  {{ game[`set${l}Score`] ? game[`set${l}Score`].split(':')[0] : '' }}
                                                </td>
                                              </template>
                                            </template>
                                            <td>{{ game | currentPeriod3('homeTotalScore') }}</td>
                                        </tr>
                                        <tr>
                                            <template v-if="game.sportId == 11">
                                              <template v-for="l in Number(9)">
                                                <td :key="l+'titlePeriod'+game.id">
                                                  {{ game[`set${l}Score`] ? game[`set${l}Score`].split(':')[1] : '' }}
                                                </td>
                                              </template>
                                            </template>
                                            <template v-else-if="game.sportId == 3">
                                               <template v-for="l in Number(4)">
                                                <td :key="l+'titlePeriod'+game.id">
                                                  {{ game[`set${l}Score`] ? game[`set${l}Score`].split(':')[1] : '' }}
                                                </td>
                                              </template>
                                            </template>
                                            <template v-else>
                                               <template v-for="l in Number(game.currentPeriod)">
                                                <td :key="l+'titlePeriod'+game.id">
                                                  {{ game[`set${l}Score`] ? game[`set${l}Score`].split(':')[1] : '' }}
                                                </td>
                                              </template>
                                            </template>
                                            <td>{{ game | currentPeriod3('awayTotalScore') }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p class="ng-scope-gamebox-time">{{ game.subResult }}</p>
                        </div>
                    </div>
                </td>
              </tr>
            </tbody>
          </table>
          <template v-if="game.status === '종료' || game.isVisible === 'n'  || game.isSuspended === 'y' || !originList.find(e => e.isVisible === 'y')">
            <div class="live_wrap">
              <div class="ng-scope" style="">
                <table class="bet_table_2" style="height:400px; width:100%; font-weight: 700; font-size:15px;">
                  <tbody>
                  <tr>
                    <td style="color: white;"> 현재 가능한 베팅이 없습니다.<br><br><br> 베팅이 가능한 항목은 화면에 자동으로 나타납니다.</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
          <template v-else>
            <div style="padding:5px 0">
              <table class="sports_in_tab" cellpadding="0" cellspacing="0">
                <tbody>
                <tr>
                  <td>
                    <span class="sports_in_tab_off_1" @click="selectMarket('STAR')">
                      <img src="@/assets/img/ico/favor_on.png"> <span
                        class="live_tab_count">{{ stared.filter(e => e.gameId === game.id).length }}</span>
                    </span>
                  </td>
                  <td>
                    <a @click="selectMarket(null)">
                      <span class="sports_in_tab_off_2" :class="{'sports_in_tab_on_2': selectedMarket === null}">
                        전체 <span class="live_tab_count">{{ originList.filter(e => e.isVisible === 'y').length }}</span>
                      </span>
                    </a>
                  </td>
                  <td>
                    <a @click="selectMarket('승무패')">
                      <span class="sports_in_tab_off_2" :class="{'sports_in_tab_on_2': selectedMarket === '승무패'}">
                        승무패 <span class="live_tab_count">{{
                          originList.filter(e => (e.market.nameKr?.indexOf('승무패') >= 0 || e.market.nameKr?.indexOf('승패') >= 0) && e.isVisible === 'y').length
                        }}</span>
                      </span>
                    </a>
                  </td>
                  <td>
                    <a @click="selectMarket('오버언더')">
                      <span class="sports_in_tab_off_2" :class="{'sports_in_tab_on_2': selectedMarket === '오버언더'}">
                        오버/언더 <span class="live_tab_count">{{
                          originList.filter(e => e.market.nameKr?.indexOf('오버언더') >= 0 && e.isVisible === 'y').length
                        }}</span>
                      </span>
                    </a>
                  </td>
                  <td>
                    <a @click="selectMarket('핸디캡')">
                      <span class="sports_in_tab_off_2" :class="{'sports_in_tab_on_2': selectedMarket === '핸디캡'}">
                        핸디캡 <span class="live_tab_count">{{
                          originList.filter(e => e.market.nameKr?.indexOf('핸디캡') >= 0 && e.isVisible === 'y').length
                        }}</span>
                      </span>
                    </a>
                  </td>
                  <td>
                    <a @click="selectMarket('ETC')">
                      <span class="sports_in_tab_off_2" :class="{'sports_in_tab_on_2': selectedMarket === 'ETC'}">
                        기타  <span class="live_tab_count">{{
                          originList.filter(e =>
                              (e.market.nameKr?.indexOf('핸디캡') === -1 &&
                                  e.market.nameKr?.indexOf('승패') === -1 &&
                                  e.market.nameKr?.indexOf('승패') === -1 &&
                                  e.market.nameKr?.indexOf('승무패') === -1 &&
                                  e.market.nameKr?.indexOf('오버언더') === -1) && e.isVisible === 'y'
                          ).length
                        }}</span>
                      </span>
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
              <template v-if="!selectedMarket">
                <template v-for="(row, index) in marketSort(originList)">
                  <template v-if="!checkBlockGame(game)">
                    <template v-if="row.nameEn?.indexOf('{sw}') >= 0">
                      <template v-for="period in uniqPeriod(row.id)">
                        <template>
                          <div
                              class="accordion-header_2  open"
                              :key="'europeanFoldersDetail'+index+game.seq+period"
                              v-if="originList.filter(e => String(e.marketId) === String(row.id) && String(e.periodSequence) === String(period))?.find(f => f.isVisible === 'y')"
                          >
                            <span>
                          <a href="#" class="left_menu_in_1_l_1">
                            <img v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === period)"
                                 @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_on.png">
                            <img v-else @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_off.png">
                          </a>
                        </span>
                            <span style="margin-left:10px;">
                            <b v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0 ">
                              {{ row | marketName(period) }}
                          </b>
                          <b v-else>
                            {{ row | teamMarketNamePeriod(game.homeTeam, game.awayTeam, period) }}
                          </b>
                        </span>
                            <span style="float: right; width:20px;" @click="folderClosed(row.seq, period)"
                                  v-if="!closed.find(e => e.seq === row.seq && e.period === period)"><img
                                src="@/assets/img/ico/accordion-opened.png" alt=""></span>
                            <span style="float: right; width:20px;" @click="folderClosed(row.seq, period)" v-else><img
                                src="@/assets/img/ico/accordion-closed.png" alt=""></span>
                          </div>
                          <div :key="'europeanFoldersDetailOdds'+period+row.seq+index"
                               v-if="!closed.find(e => e.seq === row.seq && e.period === period)">
                            <table class="nav-tabs" cellpadding="0" cellspacing="0" style="width:100%; margin: 0;">
                              <tbody>
                              <template
                                  v-for="(row2, index2) in originList.filter(e => String(e.marketId) === String(row.id) && String(e.periodSequence) === String(period))"
                              >
                                <template v-if="row2.bets.length < 4">
                                  <tr :key="'europeanfolders2'+index2" v-if="row2.isVisible === 'y'">
                                    <template v-if="row2.isSuspended === 'y'">
                                      <DetailLock
                                          :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Team1')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.homeTeam"
                                          :width="row2.bets.length"
                                      ></DetailLock>
                                      <DetailLock
                                          v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                          :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.homeTeam"
                                          :width="row2.bets.length"
                                      ></DetailLock>
                                      <DetailLock
                                          :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name ==='Away' || e.name === 'Team2')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.awayTeam"
                                          :width="row2.bets.length"
                                      ></DetailLock>
                                    </template>
                                    <template v-else>
                                      <DetailPrice
                                          :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Yes' || e.name === 'Team1')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.homeTeam"
                                          :width="row2.bets.length"
                                      ></DetailPrice>
                                      <DetailPrice
                                          v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                          :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.homeTeam"
                                          :width="row2.bets.length"
                                      ></DetailPrice>
                                      <DetailPrice
                                          :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even'|| e.name ==='Away' || e.name === 'No' || e.name === 'Team2')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.awayTeam"
                                          :width="row2.bets.length"
                                      ></DetailPrice>
                                    </template>
                                  </tr>
                                </template>
                                <template v-else>
                                  <template v-for="l in arrLenDiv(row2.bets)">
                                    <tr v-if="row2.isVisible === 'y'" :key="'CorrectScore'+l+row2.seq">
                                      <template v-for="bet in row2.bets.slice((l - 1) * 4, l * 4)">
                                        <template v-if="bet.isVisible === 'n' || bet.isSuspended === 'y' || Number(bet.price) === 0">
                                          <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">
                                          </DetailCorrectScoreLock>
                                        </template>
                                        <template v-else>
                                          <DetailCorrectScorePrice
                                              :key="'betDetail'+bet.id"
                                              :bets="bet"
                                              :folder="row2"
                                              :add-cart="addCart"
                                              :check-cart="checkCart"
                                              :game="game"
                                          >
                                          </DetailCorrectScorePrice>
                                        </template>
                                      </template>

                                    </tr>
                                  </template>
                                </template>
                              </template>
                              </tbody>
                            </table>
                          </div>
                        </template>
                      </template>
                    </template>
                    <template v-else>
                      <div class="accordion-header_2" :key="'europeanFoldersDetail'+row.seq+index"
                           v-if="originList.filter(e => String(e.marketId) === String(row.id))?.find(f => f.isVisible === 'y')"
                      >
                        <span>
                          <a href="#" class="left_menu_in_1_l_1">
                            <a href="#" class="left_menu_in_1_l_1">
                              <img v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === '0')"
                                   @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_on.png">
                              <img v-else @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_off.png">
                            </a>
                          </a>
                        </span>
                        <span style="margin-left:10px;">
                          <b v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0 ">
                              {{ row | marketName }}
                          </b>
                          <b v-else>
                            {{ row | teamMarketName(game.homeTeam, game.awayTeam) }}
                          </b>
                        </span>
                        <span style="float: right; width:20px;" @click="folderClosed(row.seq, '0')"
                              v-if="!closed.find(e => e.seq === row.seq && e.period === '0')">
                          <img src="@/assets/img/ico/accordion-opened.png" alt="">
                        </span>
                        <span style="float: right; width:20px;" @click="folderClosed(row.seq, '0')" v-else>
                          <img src="@/assets/img/ico/accordion-closed.png" alt="">
                        </span>
                      </div>
                      <div v-if="!closed.find(e => e.seq === row.seq && e.period === '0')" :key="'europeanFoldersDetailOdds'+row.seq+index">
                        <table class="nav-tabs" cellpadding="0" cellspacing="0" style="width:100%; margin: 0;">
                          <tbody>
                          <template v-for="(row2, index2) in originList.filter(e => String(e.marketId) === String(row.id))">
                            <template v-if="row2.bets.length < 4">
                              <template v-if="row.nameKr.indexOf('정확한') >= 0">
                                <template v-for="l in arrLenDiv(row2.bets)">
                                  <tr v-if="row2.isVisible === 'y'"
                                      :key="'CorrectScore'+l+row2.seq">
                                    <template v-if="row2.isSuspended === 'y'">
                                      <template v-for="bet in row2.bets">
                                        <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">
                                        </DetailCorrectScoreLock>
                                      </template>
                                    </template>
                                    <template v-else>
                                      <template v-for="bet in row2.bets.slice((l - 1) * 4, l * 4)">
                                        <template v-if="bet.isVisible === 'y'">
                                          <DetailCorrectScorePrice
                                              :key="'betDetail'+bet.id"
                                              :bets="bet"
                                              :folder="row2"
                                              :add-cart="addCart"
                                              :check-cart="checkCart"
                                              :game="game"
                                          >
                                          </DetailCorrectScorePrice>
                                        </template>
                                        <template v-else>
                                          <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">
                                          </DetailCorrectScoreLock>
                                        </template>
                                      </template>
                                    </template>
                                  </tr>
                                </template>
                              </template>
                              <template v-else>
                                <tr :key="'europeanfolders2'+index2" v-if="row2.isVisible === 'y' && !row2.bets.find(b => Number(b.price) === 0)">
                                  <template v-if="row2.isSuspended === 'y'">
                                    <DetailLock
                                        :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home')"
                                        :folder="row2"
                                        :add-cart="addCart"
                                        :check-cart="checkCart"
                                        :game="game"
                                        :team="game.homeTeam"
                                        :width="row2.bets.length"
                                    ></DetailLock>
                                    <DetailLock
                                        v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                        :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                        :folder="row2"
                                        :add-cart="addCart"
                                        :check-cart="checkCart"
                                        :game="game"
                                        :team="game.homeTeam"
                                        :width="row2.bets.length"
                                    ></DetailLock>
                                    <DetailLock
                                        :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name ==='Away')"
                                        :folder="row2"
                                        :add-cart="addCart"
                                        :check-cart="checkCart"
                                        :game="game"
                                        :team="game.awayTeam"
                                        :width="row2.bets.length"
                                    ></DetailLock>
                                  </template>
                                  <template v-else>
                                    <DetailPrice
                                        :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Yes')"
                                        :folder="row2"
                                        :add-cart="addCart"
                                        :check-cart="checkCart"
                                        :game="game"
                                        :team="game.homeTeam"
                                        :width="row2.bets.length"
                                    ></DetailPrice>
                                    <DetailPrice
                                        v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                        :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                        :folder="row2"
                                        :add-cart="addCart"
                                        :check-cart="checkCart"
                                        :game="game"
                                        :team="game.homeTeam"
                                        :width="row2.bets.length"
                                    ></DetailPrice>
                                    <DetailPrice
                                        :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even'|| e.name ==='Away' || e.name === 'No')"
                                        :folder="row2"
                                        :add-cart="addCart"
                                        :check-cart="checkCart"
                                        :game="game"
                                        :team="game.awayTeam"
                                        :width="row2.bets.length"
                                    ></DetailPrice>
                                  </template>
                                </tr>
                              </template>
                            </template>
                            <template v-else>
                              <template v-for="l in arrLenDiv(row2.bets)">
                                <tr v-if="row2.isVisible === 'y'" :key="'CorrectScore'+l+row2.seq">
                                  <template v-for="bet in row2.bets.slice((l - 1) * 4, l * 4)">
                                    <template v-if="bet.isVisible === 'n' || bet.isSuspended === 'y' || Number(bet.price) === 0">
                                      <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">
                                      </DetailCorrectScoreLock>
                                    </template>
                                    <template v-else>
                                      <DetailCorrectScorePrice
                                          :key="'betDetail'+bet.id"
                                          :bets="bet"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                      >
                                      </DetailCorrectScorePrice>
                                    </template>
                                  </template>

                                </tr>
                              </template>
                            </template>
                          </template>
                          </tbody>
                        </table>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="row.isCheck === 'y'">
                      <template v-if="row.nameEn?.indexOf('{sw}') >= 0">
                        <template v-for="period in uniqPeriod(row.id)">
                          <template>
                            <div
                                class="accordion-header_2  open"
                                :key="'europeanFoldersDetail'+index+game.seq+period"
                                v-if="originList.filter(e => String(e.marketId) === String(row.id) && String(e.periodSequence) === String(period))?.find(f => f.isVisible === 'y')"
                            >
                            <span>
                          <a href="#" class="left_menu_in_1_l_1">
                            <img v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === period)"
                                 @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_on.png">
                            <img v-else @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_off.png">
                          </a>
                        </span>
                              <span style="margin-left:10px;">
                            <b v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0 ">
                              {{ row | marketName(period) }}
                          </b>
                          <b v-else>
                            {{ row | teamMarketNamePeriod(game.homeTeam, game.awayTeam, period) }}
                          </b>
                        </span>
                              <span style="float: right; width:20px;" @click="folderClosed(row.seq, period)"
                                    v-if="!closed.find(e => e.seq === row.seq && e.period === period)"><img
                                  src="@/assets/img/ico/accordion-opened.png" alt=""></span>
                              <span style="float: right; width:20px;" @click="folderClosed(row.seq, period)" v-else><img
                                  src="@/assets/img/ico/accordion-closed.png" alt=""></span>
                            </div>
                            <div :key="'europeanFoldersDetailOdds'+period+row.seq+index"
                                 v-if="!closed.find(e => e.seq === row.seq && e.period === period)">
                              <table class="nav-tabs" cellpadding="0" cellspacing="0" style="width:100%; margin: 0;">
                                <tbody>
                                <template
                                    v-for="(row2, index2) in originList.filter(e => String(e.marketId) === String(row.id) && String(e.periodSequence) === String(period))"
                                >
                                  <template v-if="row2.bets.length < 4">
                                    <tr :key="'europeanfolders2'+index2" v-if="row2.isVisible === 'y' && !row2.bets.find(b => Number(b.price) === 0)">
                                      <template v-if="row2.isSuspended === 'y'">
                                        <DetailLock
                                            :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Team1')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :game="game"
                                            :team="game.homeTeam"
                                            :width="row2.bets.length"
                                        ></DetailLock>
                                        <DetailLock
                                            v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                            :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :game="game"
                                            :team="game.homeTeam"
                                            :width="row2.bets.length"
                                        ></DetailLock>
                                        <DetailLock
                                            :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name ==='Away' || e.name === 'Team2')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :game="game"
                                            :team="game.awayTeam"
                                            :width="row2.bets.length"
                                        ></DetailLock>
                                      </template>
                                      <template v-else>
                                        <DetailPrice
                                            :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Yes' || e.name === 'Team1')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :game="game"
                                            :team="game.homeTeam"
                                            :width="row2.bets.length"
                                        ></DetailPrice>
                                        <DetailPrice
                                            v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                            :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :game="game"
                                            :team="game.homeTeam"
                                            :width="row2.bets.length"
                                        ></DetailPrice>
                                        <DetailPrice
                                            :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even'|| e.name ==='Away' || e.name === 'No' || e.name === 'Team2')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :game="game"
                                            :team="game.awayTeam"
                                            :width="row2.bets.length"
                                        ></DetailPrice>
                                      </template>
                                    </tr>
                                  </template>
                                  <template v-else>
                                    <template v-for="l in arrLenDiv(row2.bets)">
                                      <tr v-if="row2.isVisible === 'y'" :key="'CorrectScore'+l+row2.seq">
                                        <template v-for="bet in row2.bets.slice((l - 1) * 4, l * 4)">
                                          <template v-if="bet.isVisible === 'n' || bet.isSuspended === 'y' || Number(bet.price) === 0">
                                            <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">
                                            </DetailCorrectScoreLock>
                                          </template>
                                          <template v-else>
                                            <DetailCorrectScorePrice
                                                :key="'betDetail'+bet.id"
                                                :bets="bet"
                                                :folder="row2"
                                                :add-cart="addCart"
                                                :check-cart="checkCart"
                                                :game="game"
                                            >
                                            </DetailCorrectScorePrice>
                                          </template>
                                        </template>

                                      </tr>
                                    </template>
                                  </template>
                                </template>
                                </tbody>
                              </table>
                            </div>
                          </template>
                        </template>
                      </template>
                      <template v-else>
                        <div class="accordion-header_2" :key="'europeanFoldersDetail'+row.seq+index"
                             v-if="originList.filter(e => String(e.marketId) === String(row.id))?.find(f => f.isVisible === 'y')"
                        >
                        <span>
                          <a href="#" class="left_menu_in_1_l_1">
                            <a href="#" class="left_menu_in_1_l_1">
                              <img v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === '0')"
                                   @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_on.png">
                              <img v-else @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_off.png">
                            </a>
                          </a>
                        </span>
                          <span style="margin-left:10px;">
                          <b v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0 ">
                              {{ row | marketName }}
                          </b>
                          <b v-else>
                            {{ row | teamMarketName(game.homeTeam, game.awayTeam) }}
                          </b>
                        </span>
                          <span style="float: right; width:20px;" @click="folderClosed(row.seq, '0')"
                                v-if="!closed.find(e => e.seq === row.seq && e.period === '0')">
                          <img src="@/assets/img/ico/accordion-opened.png" alt="">
                        </span>
                          <span style="float: right; width:20px;" @click="folderClosed(row.seq, '0')" v-else>
                          <img src="@/assets/img/ico/accordion-closed.png" alt="">
                        </span>
                        </div>
                        <div v-if="!closed.find(e => e.seq === row.seq && e.period === '0')" :key="'europeanFoldersDetailOdds'+row.seq+index">
                          <table class="nav-tabs" cellpadding="0" cellspacing="0" style="width:100%; margin: 0;">
                            <tbody>
                            <template v-for="(row2, index2) in originList.filter(e => String(e.marketId) === String(row.id))">
                              <template v-if="row2.bets.length < 4">
                                <template v-if="row.nameKr.indexOf('정확한') >= 0">
                                  <template v-for="l in arrLenDiv(row2.bets)">
                                    <tr v-if="row2.isVisible === 'y'"
                                        :key="'CorrectScore'+l+row2.seq">
                                      <template v-if="row2.isSuspended === 'y'">
                                        <template v-for="bet in row2.bets">
                                          <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">
                                          </DetailCorrectScoreLock>
                                        </template>
                                      </template>
                                      <template v-else>
                                        <template v-for="bet in row2.bets.slice((l - 1) * 4, l * 4)">
                                          <template v-if="bet.isVisible === 'y'">
                                            <DetailCorrectScorePrice
                                                :key="'betDetail'+bet.id"
                                                :bets="bet"
                                                :folder="row2"
                                                :add-cart="addCart"
                                                :check-cart="checkCart"
                                                :game="game"
                                            >
                                            </DetailCorrectScorePrice>
                                          </template>
                                          <template v-else>
                                            <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">
                                            </DetailCorrectScoreLock>
                                          </template>
                                        </template>
                                      </template>
                                    </tr>
                                  </template>
                                </template>
                                <template v-else>
                                  <tr :key="'europeanfolders2'+index2" v-if="row2.isVisible === 'y' && !row2.bets.find(b => Number(b.price) === 0)">
                                    <template v-if="row2.isSuspended === 'y'">
                                      <DetailLock
                                          :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.homeTeam"
                                          :width="row2.bets.length"
                                      ></DetailLock>
                                      <DetailLock
                                          v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                          :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.homeTeam"
                                          :width="row2.bets.length"
                                      ></DetailLock>
                                      <DetailLock
                                          :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name ==='Away')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.awayTeam"
                                          :width="row2.bets.length"
                                      ></DetailLock>
                                    </template>
                                    <template v-else>
                                      <DetailPrice
                                          :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Yes')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.homeTeam"
                                          :width="row2.bets.length"
                                      ></DetailPrice>
                                      <DetailPrice
                                          v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                          :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.homeTeam"
                                          :width="row2.bets.length"
                                      ></DetailPrice>
                                      <DetailPrice
                                          :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even'|| e.name ==='Away' || e.name === 'No')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.awayTeam"
                                          :width="row2.bets.length"
                                      ></DetailPrice>
                                    </template>
                                  </tr>
                                </template>
                              </template>
                              <template v-else>
                                <template v-for="l in arrLenDiv(row2.bets)">
                                  <tr v-if="row2.isVisible === 'y'" :key="'CorrectScore'+l+row2.seq">
                                    <template v-for="bet in row2.bets.slice((l - 1) * 4, l * 4)">
                                      <template v-if="bet.isVisible === 'n' || bet.isSuspended === 'y' || Number(bet.price) === 0">
                                        <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">
                                        </DetailCorrectScoreLock>
                                      </template>
                                      <template v-else>
                                        <DetailCorrectScorePrice
                                            :key="'betDetail'+bet.id"
                                            :bets="bet"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :game="game"
                                        >
                                        </DetailCorrectScorePrice>
                                      </template>
                                    </template>

                                  </tr>
                                </template>
                              </template>
                            </template>
                            </tbody>
                          </table>
                        </div>
                      </template>
                    </template>
                  </template>
                </template>
              </template>
              <template v-else>
                <template v-for="(row, index) in marketSort(originList)">
                  <template v-if="!checkBlockGame(game)">
                    <template v-if="row.nameEn?.indexOf('{sw}') >= 0">
                      <template v-for="period in uniqPeriod(row.id)">
                        <template>
                          <div
                              class="accordion-header_2  open"
                              :key="'europeanFoldersDetail'+index+game.seq+period"
                              v-if="originList.filter(e => String(e.marketId) === String(row.id) && String(e.periodSequence) === String(period))?.find(f => f.isVisible === 'y')"
                          >
                            <span>
                          <a href="#" class="left_menu_in_1_l_1">
                            <img
                                v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === period)"
                                @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_on.png">
                            <img v-else @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_off.png">
                          </a>
                        </span>
                            <span style="margin-left:10px;">
                            <b v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0 ">
                              {{ row | marketName(period) }}
                          </b>
                          <b v-else>
                            {{ row | teamMarketNamePeriod(game.homeTeam, game.awayTeam, period) }}
                          </b>
                        </span>
                            <span style="float: right; width:20px;" @click="folderClosed(row.seq, period)"
                                  v-if="!closed.find(e => e.seq === row.seq && e.period === period)"><img
                                src="@/assets/img/ico/accordion-opened.png" alt=""></span>
                            <span style="float: right; width:20px;" @click="folderClosed(row.seq, period)" v-else><img
                                src="@/assets/img/ico/accordion-closed.png" alt=""></span>
                          </div>
                          <div :key="'europeanFoldersDetailOdds'+period+row.seq+index"
                               v-if="!closed.find(e => e.seq === row.seq && e.period === period)">
                            <table class="nav-tabs" cellpadding="0" cellspacing="0" style="width:100%; margin: 0;">
                              <tbody>
                              <template
                                  v-for="(row2, index2) in originList.filter(e => String(e.marketId) === String(row.id) && String(e.periodSequence) === String(period))"
                              >
                                <template v-if="row2.bets.length < 4">
                                  <tr :key="'europeanfolders2'+index2"
                                      v-if="row2.isVisible === 'y' && !row2.bets.find(b => Number(b.price) === 0)">
                                    <template v-if="row2.isSuspended === 'y'">
                                      <DetailLock
                                          :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Team1')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.homeTeam"
                                          :width="row2.bets.length"
                                      ></DetailLock>
                                      <DetailLock
                                          v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                          :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.homeTeam"
                                          :width="row2.bets.length"
                                      ></DetailLock>
                                      <DetailLock
                                          :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name ==='Away' || e.name === 'Team2')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.awayTeam"
                                          :width="row2.bets.length"
                                      ></DetailLock>
                                    </template>
                                    <template v-else>
                                      <DetailPrice
                                          :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Yes' || e.name === 'Team1')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.homeTeam"
                                          :width="row2.bets.length"
                                      ></DetailPrice>
                                      <DetailPrice
                                          v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                          :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.homeTeam"
                                          :width="row2.bets.length"
                                      ></DetailPrice>
                                      <DetailPrice
                                          :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even'|| e.name ==='Away' || e.name === 'No' || e.name === 'Team2')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.awayTeam"
                                          :width="row2.bets.length"
                                      ></DetailPrice>
                                    </template>
                                  </tr>
                                </template>
                                <template v-else>
                                  <template v-for="l in arrLenDiv(row2.bets)">
                                    <tr v-if="row2.isVisible === 'y'" :key="'CorrectScore'+l+row2.seq">
                                      <template v-for="bet in row2.bets.slice((l - 1) * 4, l * 4)">
                                        <template
                                            v-if="bet.isVisible === 'n' || bet.isSuspended === 'y' || Number(bet.price) === 0">
                                          <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">
                                          </DetailCorrectScoreLock>
                                        </template>
                                        <template v-else>
                                          <DetailCorrectScorePrice
                                              :key="'betDetail'+bet.id"
                                              :bets="bet"
                                              :folder="row2"
                                              :add-cart="addCart"
                                              :check-cart="checkCart"
                                              :game="game"
                                          >
                                          </DetailCorrectScorePrice>
                                        </template>
                                      </template>

                                    </tr>
                                  </template>
                                </template>
                              </template>
                              </tbody>
                            </table>
                          </div>
                        </template>
                      </template>
                    </template>
                    <template v-else>
                      <div class="accordion-header_2" :key="'europeanFoldersDetail'+row.seq+index"
                           v-if="originList.filter(e => String(e.marketId) === String(row.id))?.find(f => f.isVisible === 'y')"
                      >
                        <span>
                          <a href="#" class="left_menu_in_1_l_1">
                            <a href="#" class="left_menu_in_1_l_1">
                              <img
                                  v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === '0')"
                                  @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_on.png">
                              <img v-else @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_off.png">
                            </a>
                          </a>
                        </span>
                        <span style="margin-left:10px;">
                          <b v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0 ">
                              {{ row | marketName }}
                          </b>
                          <b v-else>
                            {{ row | teamMarketName(game.homeTeam, game.awayTeam) }}
                          </b>
                        </span>
                        <span style="float: right; width:20px;" @click="folderClosed(row.seq, '0')"
                              v-if="!closed.find(e => e.seq === row.seq && e.period === '0')">
                          <img src="@/assets/img/ico/accordion-opened.png" alt="">
                        </span>
                        <span style="float: right; width:20px;" @click="folderClosed(row.seq, '0')" v-else>
                          <img src="@/assets/img/ico/accordion-closed.png" alt="">
                        </span>
                      </div>
                      <div v-if="!closed.find(e => e.seq === row.seq && e.period === '0')"
                           :key="'europeanFoldersDetailOdds'+row.seq+index">
                        <table class="nav-tabs" cellpadding="0" cellspacing="0" style="width:100%; margin: 0;">
                          <tbody>
                          <template
                              v-for="(row2, index2) in originList.filter(e => String(e.marketId) === String(row.id))">
                            <template v-if="row2.bets.length < 4">
                              <template v-if="row.nameKr.indexOf('정확한') >= 0">
                                <template v-for="l in arrLenDiv(row2.bets)">
                                  <tr v-if="row2.isVisible === 'y'"
                                      :key="'CorrectScore'+l+row2.seq">
                                    <template v-if="row2.isSuspended === 'y'">
                                      <template v-for="bet in row2.bets">
                                        <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">
                                        </DetailCorrectScoreLock>
                                      </template>
                                    </template>
                                    <template v-else>
                                      <template v-for="bet in row2.bets.slice((l - 1) * 4, l * 4)">
                                        <template v-if="bet.isVisible === 'y'">
                                          <DetailCorrectScorePrice
                                              :key="'betDetail'+bet.id"
                                              :bets="bet"
                                              :folder="row2"
                                              :add-cart="addCart"
                                              :check-cart="checkCart"
                                              :game="game"
                                          >
                                          </DetailCorrectScorePrice>
                                        </template>
                                        <template v-else>
                                          <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">
                                          </DetailCorrectScoreLock>
                                        </template>
                                      </template>
                                    </template>
                                  </tr>
                                </template>
                              </template>
                              <template v-else>
                                <tr :key="'europeanfolders2'+index2"
                                    v-if="row2.isVisible === 'y' && !row2.bets.find(b => Number(b.price) === 0)">
                                  <template v-if="row2.isSuspended === 'y'">
                                    <DetailLock
                                        :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home')"
                                        :folder="row2"
                                        :add-cart="addCart"
                                        :check-cart="checkCart"
                                        :game="game"
                                        :team="game.homeTeam"
                                        :width="row2.bets.length"
                                    ></DetailLock>
                                    <DetailLock
                                        v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                        :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                        :folder="row2"
                                        :add-cart="addCart"
                                        :check-cart="checkCart"
                                        :game="game"
                                        :team="game.homeTeam"
                                        :width="row2.bets.length"
                                    ></DetailLock>
                                    <DetailLock
                                        :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name ==='Away')"
                                        :folder="row2"
                                        :add-cart="addCart"
                                        :check-cart="checkCart"
                                        :game="game"
                                        :team="game.awayTeam"
                                        :width="row2.bets.length"
                                    ></DetailLock>
                                  </template>
                                  <template v-else>
                                    <DetailPrice
                                        :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Yes')"
                                        :folder="row2"
                                        :add-cart="addCart"
                                        :check-cart="checkCart"
                                        :game="game"
                                        :team="game.homeTeam"
                                        :width="row2.bets.length"
                                    ></DetailPrice>
                                    <DetailPrice
                                        v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                        :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                        :folder="row2"
                                        :add-cart="addCart"
                                        :check-cart="checkCart"
                                        :game="game"
                                        :team="game.homeTeam"
                                        :width="row2.bets.length"
                                    ></DetailPrice>
                                    <DetailPrice
                                        :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even'|| e.name ==='Away' || e.name === 'No')"
                                        :folder="row2"
                                        :add-cart="addCart"
                                        :check-cart="checkCart"
                                        :game="game"
                                        :team="game.awayTeam"
                                        :width="row2.bets.length"
                                    ></DetailPrice>
                                  </template>
                                </tr>
                              </template>
                            </template>
                            <template v-else>
                              <template v-for="l in arrLenDiv(row2.bets)">
                                <tr v-if="row2.isVisible === 'y'" :key="'CorrectScore'+l+row2.seq">
                                  <template v-for="bet in row2.bets.slice((l - 1) * 4, l * 4)">
                                    <template
                                        v-if="bet.isVisible === 'n' || bet.isSuspended === 'y' || Number(bet.price) === 0">
                                      <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">
                                      </DetailCorrectScoreLock>
                                    </template>
                                    <template v-else>
                                      <DetailCorrectScorePrice
                                          :key="'betDetail'+bet.id"
                                          :bets="bet"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                      >
                                      </DetailCorrectScorePrice>
                                    </template>
                                  </template>

                                </tr>
                              </template>
                            </template>
                          </template>
                          </tbody>
                        </table>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="row.isCheck === 'y'">
                      <template v-if="row.nameEn?.indexOf('{sw}') >= 0">
                        <template v-for="period in uniqPeriod(row.id)">
                          <template>
                            <div
                                class="accordion-header_2  open"
                                :key="'europeanFoldersDetail'+index+game.seq+period"
                                v-if="originList.filter(e => String(e.marketId) === String(row.id) && String(e.periodSequence) === String(period))?.find(f => f.isVisible === 'y')"
                            >
                            <span>
                          <a href="#" class="left_menu_in_1_l_1">
                            <img
                                v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === period)"
                                @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_on.png">
                            <img v-else @click="addStartedMarket(row.seq, period)" src="@/assets/img/ico/favor_off.png">
                          </a>
                        </span>
                              <span style="margin-left:10px;">
                            <b v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0 ">
                              {{ row | marketName(period) }}
                          </b>
                          <b v-else>
                            {{ row | teamMarketNamePeriod(game.homeTeam, game.awayTeam, period) }}
                          </b>
                        </span>
                              <span style="float: right; width:20px;" @click="folderClosed(row.seq, period)"
                                    v-if="!closed.find(e => e.seq === row.seq && e.period === period)"><img
                                  src="@/assets/img/ico/accordion-opened.png" alt=""></span>
                              <span style="float: right; width:20px;" @click="folderClosed(row.seq, period)" v-else><img
                                  src="@/assets/img/ico/accordion-closed.png" alt=""></span>
                            </div>
                            <div :key="'europeanFoldersDetailOdds'+period+row.seq+index"
                                 v-if="!closed.find(e => e.seq === row.seq && e.period === period)">
                              <table class="nav-tabs" cellpadding="0" cellspacing="0" style="width:100%; margin: 0;">
                                <tbody>
                                <template
                                    v-for="(row2, index2) in originList.filter(e => String(e.marketId) === String(row.id) && String(e.periodSequence) === String(period))"
                                >
                                  <template v-if="row2.bets.length < 4">
                                    <tr :key="'europeanfolders2'+index2"
                                        v-if="row2.isVisible === 'y' && !row2.bets.find(b => Number(b.price) === 0)">
                                      <template v-if="row2.isSuspended === 'y'">
                                        <DetailLock
                                            :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Team1')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :game="game"
                                            :team="game.homeTeam"
                                            :width="row2.bets.length"
                                        ></DetailLock>
                                        <DetailLock
                                            v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                            :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :game="game"
                                            :team="game.homeTeam"
                                            :width="row2.bets.length"
                                        ></DetailLock>
                                        <DetailLock
                                            :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name ==='Away' || e.name === 'Team2')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :game="game"
                                            :team="game.awayTeam"
                                            :width="row2.bets.length"
                                        ></DetailLock>
                                      </template>
                                      <template v-else>
                                        <DetailPrice
                                            :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Yes' || e.name === 'Team1')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :game="game"
                                            :team="game.homeTeam"
                                            :width="row2.bets.length"
                                        ></DetailPrice>
                                        <DetailPrice
                                            v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                            :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :game="game"
                                            :team="game.homeTeam"
                                            :width="row2.bets.length"
                                        ></DetailPrice>
                                        <DetailPrice
                                            :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even'|| e.name ==='Away' || e.name === 'No' || e.name === 'Team2')"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :game="game"
                                            :team="game.awayTeam"
                                            :width="row2.bets.length"
                                        ></DetailPrice>
                                      </template>
                                    </tr>
                                  </template>
                                  <template v-else>
                                    <template v-for="l in arrLenDiv(row2.bets)">
                                      <tr v-if="row2.isVisible === 'y'" :key="'CorrectScore'+l+row2.seq">
                                        <template v-for="bet in row2.bets.slice((l - 1) * 4, l * 4)">
                                          <template
                                              v-if="bet.isVisible === 'n' || bet.isSuspended === 'y' || Number(bet.price) === 0">
                                            <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">
                                            </DetailCorrectScoreLock>
                                          </template>
                                          <template v-else>
                                            <DetailCorrectScorePrice
                                                :key="'betDetail'+bet.id"
                                                :bets="bet"
                                                :folder="row2"
                                                :add-cart="addCart"
                                                :check-cart="checkCart"
                                                :game="game"
                                            >
                                            </DetailCorrectScorePrice>
                                          </template>
                                        </template>

                                      </tr>
                                    </template>
                                  </template>
                                </template>
                                </tbody>
                              </table>
                            </div>
                          </template>
                        </template>
                      </template>
                      <template v-else>
                        <div class="accordion-header_2" :key="'europeanFoldersDetail'+row.seq+index"
                             v-if="originList.filter(e => String(e.marketId) === String(row.id))?.find(f => f.isVisible === 'y')"
                        >
                        <span>
                          <a href="#" class="left_menu_in_1_l_1">
                            <a href="#" class="left_menu_in_1_l_1">
                              <img
                                  v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === '0')"
                                  @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_on.png">
                              <img v-else @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_off.png">
                            </a>
                          </a>
                        </span>
                          <span style="margin-left:10px;">
                          <b v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0 ">
                              {{ row | marketName }}
                          </b>
                          <b v-else>
                            {{ row | teamMarketName(game.homeTeam, game.awayTeam) }}
                          </b>
                        </span>
                          <span style="float: right; width:20px;" @click="folderClosed(row.seq, '0')"
                                v-if="!closed.find(e => e.seq === row.seq && e.period === '0')">
                          <img src="@/assets/img/ico/accordion-opened.png" alt="">
                        </span>
                          <span style="float: right; width:20px;" @click="folderClosed(row.seq, '0')" v-else>
                          <img src="@/assets/img/ico/accordion-closed.png" alt="">
                        </span>
                        </div>
                        <div v-if="!closed.find(e => e.seq === row.seq && e.period === '0')"
                             :key="'europeanFoldersDetailOdds'+row.seq+index">
                          <table class="nav-tabs" cellpadding="0" cellspacing="0" style="width:100%; margin: 0;">
                            <tbody>
                            <template
                                v-for="(row2, index2) in originList.filter(e => String(e.marketId) === String(row.id))">
                              <template v-if="row2.bets.length < 4">
                                <template v-if="row.nameKr.indexOf('정확한') >= 0">
                                  <template v-for="l in arrLenDiv(row2.bets)">
                                    <tr v-if="row2.isVisible === 'y'"
                                        :key="'CorrectScore'+l+row2.seq">
                                      <template v-if="row2.isSuspended === 'y'">
                                        <template v-for="bet in row2.bets">
                                          <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">
                                          </DetailCorrectScoreLock>
                                        </template>
                                      </template>
                                      <template v-else>
                                        <template v-for="bet in row2.bets.slice((l - 1) * 4, l * 4)">
                                          <template v-if="bet.isVisible === 'y'">
                                            <DetailCorrectScorePrice
                                                :key="'betDetail'+bet.id"
                                                :bets="bet"
                                                :folder="row2"
                                                :add-cart="addCart"
                                                :check-cart="checkCart"
                                                :game="game"
                                            >
                                            </DetailCorrectScorePrice>
                                          </template>
                                          <template v-else>
                                            <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">
                                            </DetailCorrectScoreLock>
                                          </template>
                                        </template>
                                      </template>
                                    </tr>
                                  </template>
                                </template>
                                <template v-else>
                                  <tr :key="'europeanfolders2'+index2"
                                      v-if="row2.isVisible === 'y' && !row2.bets.find(b => Number(b.price) === 0)">
                                    <template v-if="row2.isSuspended === 'y'">
                                      <DetailLock
                                          :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.homeTeam"
                                          :width="row2.bets.length"
                                      ></DetailLock>
                                      <DetailLock
                                          v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                          :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.homeTeam"
                                          :width="row2.bets.length"
                                      ></DetailLock>
                                      <DetailLock
                                          :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name ==='Away')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.awayTeam"
                                          :width="row2.bets.length"
                                      ></DetailLock>
                                    </template>
                                    <template v-else>
                                      <DetailPrice
                                          :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Yes')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.homeTeam"
                                          :width="row2.bets.length"
                                      ></DetailPrice>
                                      <DetailPrice
                                          v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                          :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.homeTeam"
                                          :width="row2.bets.length"
                                      ></DetailPrice>
                                      <DetailPrice
                                          :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even'|| e.name ==='Away' || e.name === 'No')"
                                          :folder="row2"
                                          :add-cart="addCart"
                                          :check-cart="checkCart"
                                          :game="game"
                                          :team="game.awayTeam"
                                          :width="row2.bets.length"
                                      ></DetailPrice>
                                    </template>
                                  </tr>
                                </template>
                              </template>
                              <template v-else>
                                <template v-for="l in arrLenDiv(row2.bets)">
                                  <tr v-if="row2.isVisible === 'y'" :key="'CorrectScore'+l+row2.seq">
                                    <template v-for="bet in row2.bets.slice((l - 1) * 4, l * 4)">
                                      <template
                                          v-if="bet.isVisible === 'n' || bet.isSuspended === 'y' || Number(bet.price) === 0">
                                        <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">
                                        </DetailCorrectScoreLock>
                                      </template>
                                      <template v-else>
                                        <DetailCorrectScorePrice
                                            :key="'betDetail'+bet.id"
                                            :bets="bet"
                                            :folder="row2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :game="game"
                                        >
                                        </DetailCorrectScorePrice>
                                      </template>
                                    </template>

                                  </tr>
                                </template>
                              </template>
                            </template>
                            </tbody>
                          </table>
                        </div>
                      </template>
                    </template>
                  </template>
                </template>
              </template>
            </div>
          </template>
        </td>
      </tr>
      <tr v-else>
        <td colspan="10" style="text-align: center;">
          <Loading></Loading>
        </td>
      </tr>
    </tbody>
</template>

<script>
import Loading from '@/components/Loading';
import DetailPrice from './DetailPrice'
import DetailLock from './DetailLock'
import DetailCorrectScoreLock from './DetailCorrectScoreLock'
import DetailCorrectScorePrice from './DetailCorrectScorePrice'
import {mapState} from "vuex";

export default {
  name: "InpSelectedGame",
  components: {
    DetailPrice,
    DetailLock,
    DetailCorrectScoreLock,
    DetailCorrectScorePrice,
    Loading,
  },
  props: ['game', 'addCart', 'checkCart', 'checkBlockGame', 'blockTypeSetting'],
  data: function () {
    return {
      // game: null,
      originList: null,
      lists: null,
      isLoading: false,
      selectedMarket: null,
      closed: [],
      timeCheck: true,
      scoreCheck: true,
    }
  },
  mounted() {
  console.log(this.game, 'renewal')
    this.lists = this.originList;
    this.initFolder();
    this.interval = setInterval(this.initFolder2, 5000);
    this.$socket.client.on('inplayMatchStats2', this.matchStats2)
    this.$socket.client.on('inplayStats', this.matchStats)
    this.$socket.client.on('inplayMatchUpdate', this.matchUpdate)
    this.$socket.client.on('inplay', this.updateData)
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    ...mapState({
      stared: state => state.inplay.inplayStared,
      user: state => state.user.user,
    }),
  },
  watch: {
    selectedMarket: function (val) {
      if (!val) return this.lists = this.originList;
      if (val === '승무패') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11557, 11558, 11575, 11649, 11930].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11603, 11604, 11611].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 3) return [11531, 11537, 11667].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11521, 11544].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11622, 11629, 11625].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 11) return [11515, 11507, 12972, 13103].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12240, 12242, 12243, 12244, 12245, 12246, 16817, 16818].indexOf(e.marketSeq) >= 0
        });
      }

      if (val === '핸디캡') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11562, 11563, 11580, 11752, 11968].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11608, 11615, 11615, 11615].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 3) return [11532, 11539, 11668].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11904, 11724, 11546].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11623, 11631, 11626].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 11) return [11516, 11509, 17055].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12651, 12775, 12786, 12797, 12808, 12823].indexOf(e.marketSeq) >= 0
        });
      }

      if (val === '오버언더') {
        this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11559, 11560, 11561, 11577, 11753, 11969, 11592, 11586].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11605, 11607, 11606, 11612, 11612, 11612, 11613, 11613, 11613, 11614, 11614, 11614].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 3) return [11533, 11534, 11535, 11662, 11663, 11664, 11669].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11726, 11723, 11720, 12104, 11545].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11633, 11630, 11627].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 11) return [11512, 11510, 11511, 13655, 11508, 17056, 13106].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12651, 12776, 12787, 12798, 12809, 12824].indexOf(e.marketSeq) >= 0
        });
      }

      if (val === 'ETC') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11573, 11574, 11923, 12930, 11684, 11685, 11567, 11572, 11568, 11727, 13619, 11732].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11652, 11653, 11654, 11658, 11659, 11656, 11717, 11718].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 3) return [11536, 16637, 12530, 12531, 12566, 12567, 12568, 13558, 14049].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11522, 11540, 11543, 11675, 12105].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11633, 11630, 11627].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 11) return [13102, 13101].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12646, 12648, 12653, 12812, 12827, 12777, 12788, 12799, 12813, 12828, 12645, 12647, 12652, 12810, 12825, 12780, 12791, 12802, 12816, 12831, 12778, 12789, 12800, 12814, 12829, 12782, 12793, 12804, 12818, 12833, 12783, 12794, 12805, 12819, 12834, 12784, 12795, 12806, 12820, 12835].indexOf(e.marketSeq) >= 0
        });
      }


      if (val === 'STAR') {
        this.lists = [];
        this.stared.map(s => {
          const lists = this.originList.filter(l => String(s.gameId) === String(this.game.id) && String(s.seq) === String(l.marketSeq) && String(s.periodSequence) === String(l.periodSequence))
          lists.map(folder => this.lists.push(folder));
        })

        return;
      }

      this.lists = this.originList.filter(e => e.market.nameKr?.indexOf(val) >= 0);
    },
    game:{
      handler: function(val, newVal){
        //경기 시간 변동체크
        if (val && newVal && val.id !== newVal.id) this.lists = this.originList;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    initFolder: function () {
      const payload = {
        gameId: this.game.id
      }
      return this.$store.dispatch('GET_INPLAY_DETAIL', { payload })
          .then(data => {
            const result = data.data;
            if (result.success) {
              this.game = result.payload.game;
              if (!result.payload.game) this.$router.push({ path: '/sport/livematch'});
              this.originList = result.payload.list;
              this.lists = this.originList;
              this.isLoading = true;
              this.selectMarket(this.selectedMarket)
            } else {
              console.log('인플레이 경기요청 에러', result)
              this.$store.dispatch('SHOW_ERR_MSG3', result.msg);
            }
          }).catch(err => {
            this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
          })
    },
    /**
     * 토글 액션
     * */
    marketSort: function (folders) {
      const markets = folders.map(e => e.market);
      const arrUnique = markets.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.id === character.id) === idx
      });
      arrUnique.sort()
      return arrUnique
    },
    toggleDetail: function (id) {
      this.toggle(this.openDetailBet, id)
    },
    removeAtIndex: function (arr, index) {
      this.openDetailBet.splice(index, 1);
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailBet = [...arr, item]
        return;
      }

      return this.removeAtIndex(arr, index);
    },
    uniqPeriod: function (marketId) {
      const periods = this.originList.filter(e => String(e.marketId) === String(marketId)).map(e => e.periodSequence);
      let set = new Set(periods);
      const uniqueArr = [...set];
      uniqueArr.sort()
      return uniqueArr
    },
    arrLenDiv: function (arr, div = 4) {
      return Math.floor(arr.length / div) + 1
    },
    // 매치 상태 변경
    matchStats: function (data) {
      const results = JSON.parse(data);
      for (const r in results) {
        const result = results[r];
        if (String(result.EventId) === String(this.game.id)) {
          const score = result.Score;
          // console.log('stats', result);
          // console.log(score, 'stats');
          this.game.homeResult = score.split(':')[0] || 0;
          this.game.awayResult = score.split(':')[1] || 0;
          if (result.EventType !== 'period' && !result.IsTimeout) this.game.currentPeriod = result.Period;
          if (result.Set1Score) this.game.set1Score = result.Set1Score;
          if (result.Set2Score) this.game.set2Score = result.Set2Score;
          if (result.Set3Score) this.game.set3Score = result.Set3Score;
          if (result.Set4Score) this.game.set4Score = result.Set4Score;
          if (result.Set5Score) this.game.set5Score = result.Set5Score;
          if (result.Set6Score) this.game.set6Score = result.Set6Score;
          if (result.Set7Score) this.game.set7Score = result.Set7Score;
          if (result.Set8Score) this.game.set8Score = result.Set8Score;
          if (result.Set9Score) this.game.set9Score = result.Set9Score;
          // console.log(result.RemainingTime)
          if (result.RemainingTime) {
            this.game.remainTime = result.RemainingTime;
            setTimeout(()=>{
              this.game.remainTime = null;
            }, 5000)
          }
          if (result.Info) this.game.subResult = result.Info
        }
      }
    },
    // 매치 상태 업데이트
    matchUpdate: function (data) {
      const results = JSON.parse(data);
      // console.log(results, 'match')
      for (const r in results) {
        const matchData = results[r];
        if (String(this.game.id) === String(matchData.Id)) {
          if (matchData.IsStarted) this.game.status = '진행';
          this.game.isVisible = matchData.IsVisible ? 'y' : 'n';
          this.game.isSuspended = matchData.IsSuspended ? 'y' : 'n';
          if (matchData.MatchStatus === 2){
            this.game.status = '종료';
          }
        }
      }
    },
    // 인플레이 경기 자세히 내에 해당 폴더 변경
    updateData: function (data) {
      const result = JSON.parse(data);
      const gameMarket = result.filter(e => String(e.MatchId) === String(this.game.id))
      if (gameMarket.length > 0) {
        if (this.game){
          gameMarket.map(m => {
            if (this.originList){
              const folder = this.originList.find(e => String(e.id) === String(m.Id));
              if (folder) {
                folder.isVisible = m.IsVisible ? 'y' : 'n';
                folder.isSuspended = m.IsSuspended ? 'y' : 'n';
                m.Selections.map(s => {
                  const bet = folder.bets.find(b => String(b.id) === String(s.Id));
                  if (bet) {
                    bet.price = s.Price;
                    bet.isVisible = s.IsVisible ? 'y' : 'n';
                    bet.isSuspended = s.IsSuspended ? 'y' : 'n';
                  }
                })
              }
            }

            if (this.lists){
              const folder2 = this.lists.find(e => String(e.id) === String(m.Id));
              if (folder2) {
                folder2.isVisible = m.IsVisible ? 'y' : 'n';
                folder2.isSuspended = m.IsSuspended ? 'y' : 'n';
                m.Selections.map(s => {
                  const bet = folder2.bets.find(b => String(b.id) === String(s.Id));
                  if (bet) {
                    bet.price = s.Price;
                    bet.isVisible = s.IsVisible ? 'y' : 'n';
                    bet.isSuspended = s.IsSuspended ? 'y' : 'n';
                  }
                })
              }
            }
          })
        }
      }
    },
    addStartedMarket: function (seq, period) {
      const market = {
        gameId: this.game.id,
        seq: seq,
        periodSequence: period,
      }
      this.$store.dispatch('list/inplayStarted', market);
    },
    folderClosed: function (seq, period) {
      const data = {
        seq,
        period
      };
      const index = this.closed.findIndex(e => e.seq === seq && e.period === period)
      if (index >= 0) return this.closed.splice(index, 1);
      this.closed.push(data);
    },
    selectMarket: function (market) {
      this.selectedMarket = market;
    },
    matchStats2: function(data){
      const results = JSON.parse(data);
      for (const r in results) {
        const result = results[r];
        // console.log(result);
        if (String(result.EventId) === String(this.game.id)) {
          const score = result.Score;
          // console.log('stats2', result);
          this.game.homeResult = score.split(':')[0] || 0;
          this.game.awayResult = score.split(':')[1] || 0;
          if (result.EventType !== 'Finished' && result.EventType !== 'period' && !result.IsTimeout) this.game.currentPeriod = result.Period;
          if (result.Set1Score) this.game.set1Score = result.Set1Score;
          if (result.Set2Score) this.game.set2Score = result.Set2Score;
          if (result.Set3Score) this.game.set3Score = result.Set3Score;
          if (result.Set4Score) this.game.set4Score = result.Set4Score;
          if (result.Set5Score) this.game.set5Score = result.Set5Score;
          if (result.Set6Score) this.game.set6Score = result.Set6Score;
          if (result.Set7Score) this.game.set7Score = result.Set7Score;
          if (result.Set8Score) this.game.set8Score = result.Set8Score;
          if (result.Set9Score) this.game.set9Score = result.Set9Score;
          if (result.RemainingTime) this.game.remainTime = result.RemainingTime;
          if (result.DangerousAttackScore) this.game.dangerousAttack = result.DangerousAttackScore;
          if (result.ShotOnTargetScore) this.game.shotOnTargetScore = result.ShotOnTargetScore;
          if (result.ShotOffTargetScore) this.game.shotOffTargetScore = result.ShotOffTargetScore;
          if (result.Info) this.game.subResult = result.Info
        }
      }
    },
    // 스포츠별 피어리드별 타임 체크
    sportPeriodCheck: function (period) {
      if (this.game.sportSeq === 3 && Number(period) >= 4) return false;
      return true;
    },
  },
}
</script>

<style scoped>
.listTable02 th {
  height: 40px;
}
</style>

