<template>
  <div v-if="this.$store.state.page.isMobile">
    <div class="quick-cart" @click="toggleCart" v-if="cartItem.length > 0">
      <button class="btn-cart">
        <span>{{ cartItem.length }}</span>
      </button>
    </div>
    <transition name="fade">
      <div class="bet-slip-wrapper" v-if="isView">
        <div class="title-bar">
          베팅슬립 <span class="cart-count">{{ cartItem.length }}</span>
          <div class="close-X" @click="closeCart">
            <i class="fas fa-times"></i>
          </div>
        </div>
        <div class="content">
          <div class="bet-slip">
            <div class="header">
              <div class="title">베팅슬립</div>
              <div class="toggle-container">
                <div class="toggle">
                  <span style="margin-right: 3px">배당변경 자동적용</span>
                  <input
                    type="checkbox"
                    id="auto-apply"
                    v-model="autoModifyOdds"
                    @click="autoModifyOdds = !autoModifyOdds"
                  />
                  <label for="auto-apply"></label>
                  <div
                    class="delete"
                    style="margin-left: 8px"
                    @click="clearItemCart"
                  >
                    <i class="fas fa-trash"></i>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="item"
              v-for="(item, idx) in cartItem"
              :key="'MultiMcartItem' + idx"
              style="border-top: 2px solid #333"
            >
              <div class="info" style="width: 100%">
                <div class="icon border-b" style="width: 100%; margin-bottom">
                  <img :src="item.sportNameIcon" alt="" />
                  {{ item.leagueNameKr || item.leagueNameEn }}
                  <div
                    class="delete"
                    style="float: right"
                    @click="deleteItemCart(item)"
                  >
                    <i class="fas fa-trash"></i>
                  </div>
                </div>
                <div class="details">
                  <div class="team">
                    {{ item.homeNameKr || item.homeNameEn }} -
                    {{ item.awayNameKr || item.awayNameEn }}
                    <div style="float: right; color: #888">
                      {{ item.startTime | formatDate("MM월 DD일") }}
                    </div>
                  </div>
                  <div class="match2">
                    {{ item.marketNameKr || item.marketNameEn }}
                    <div style="float: right; color: #888">
                      {{ item.startTime | formatDate("HH:mm") }}
                    </div>
                  </div>
                  <div class="match">
                    {{ item | cartBetsName }}
                    <div class="odds">{{ item.odds }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer" style="width: 100%">
            <div class="free-bet" v-if="user.freeBets?.length > 0">
              <div class="title">프리벳</div>
              <div class="toggle-container">
                <div class="toggle">
                  <input
                    type="checkbox"
                    id="auto-apply"
                    v-model="isActiveFreeBet"
                    @click="isActiveFreeBet = !isActiveFreeBet"
                  />
                  <label for="auto-apply"></label>
                </div>
              </div>
            </div>
            <div class="total" v-if="isActiveFreeBet">
              <div class="label">프리벳 금액</div>
            </div>
            <div class="total" v-if="isActiveFreeBet">
              <select class="free-bet-select" v-model="selectedFreeBet">
                <option :value="null">선택</option>
                <option
                  v-for="bet in user.freeBets"
                  :key="'freebet' + bet.seq"
                  :value="bet"
                >
                  {{ bet.description }}
                </option>
              </select>
            </div>
            <div class="total" :class="{ 'mt-10': user.freeBets?.length > 0 }">
              <div class="label">총 배당률</div>
              <div class="value">{{ odds }}</div>
            </div>
            <div class="total">
              <div class="label">배팅금액</div>
              <input
                class="value"
                v-model="amount"
                v-if="!isActiveFreeBet"
                style="text-align: right"
              />
              <input
                class="value"
                v-model="amount"
                disabled
                v-if="isActiveFreeBet"
                style="text-align: right"
              />
            </div>
            <div
              class="total"
              style="border-top: 1px solid #444; padding-top: 10px"
            >
              <div class="label">예상당첨금액</div>
              <div class="value">{{ winnings | makeComma }}원</div>
            </div>
            <div class="amounts" v-if="!isActiveFreeBet">
              <div class="amount" @click="addAmount(10000)">10,000</div>
              <div class="amount" @click="addAmount(100000)">100,000</div>
              <div class="amount" @click="addAmount(500000)">500,000</div>
              <div class="amount" @click="addAmount(1000000)">1,000,000</div>
            </div>
            <div class="buttons" v-if="!isActiveFreeBet">
              <div class="button" @click="maxBet">최대배팅</div>
              <div class="button" @click="resetAmount">초기화</div>
            </div>
            <div v-if="!processing" class="bet-button" @click="processBet">
              베팅하기
            </div>
            <div v-else style="text-align: center">
              <Loading></Loading>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import MSG from "@/contants/msg";
import Loading from "@/components/Loading";
import { mapState } from "vuex";
export default {
  name: "MLsportNewCart",
  components: {
    Loading,
  },
  data: function () {
    return {
      processing: false,
      autoModifyOdds: true,
      isActiveFreeBet: false,
      selectedFreeBet: null,
      winnings: 0,
      amount: 0,
      maxOddsAmount: 0,
      limitWinnings: 0,
      maxAmount: 0,
    };
  },
  computed: {
    odds: function () {
      return Number(this.$store.getters["LIVE_TOTAL_ODDS"]);
    },
    levelSetting: function () {
      return this.$store.getters["LEVEL_SETTING"];
    },
    minAmount: function () {
      return Number(this.levelSetting.prematchSetting.minBetting);
    },
    maxWinAmount: function () {
      return Number(this.levelSetting.prematchSetting.live.maxWin);
    },
    maxNormalBetAmount: function () {
      return Number(this.levelSetting.prematchSetting.live.maxBetting);
    },
    maxOneFolderAmount: function () {
      return Number(this.levelSetting.prematchSetting.live.maxBetting);
    },
    maxOneFolderWinning: function () {
      return Number(this.levelSetting.prematchSetting.live.maxWin);
    },
    maxOdds: function () {
      return this.levelSetting.prematchSetting.maxOdds;
    },
    oneFolderDeduction: function () {
      return this.levelSetting.levelOption.deduction.one;
    },
    twoFolderDeduction: function () {
      return this.levelSetting.levelOption.deduction.two;
    },
    threeFolderDeduction: function () {
      return this.levelSetting.levelOption.deduction.three;
    },
    fourFolderDeduction: function () {
      return this.levelSetting.levelOption.deduction.four;
    },
    fiveFolderDeduction: function () {
      return this.levelSetting.levelOption.deduction.five;
    },
    ...mapState({
      isView: (state) => state.modals.cart,
      user: (state) => state.user.user,
      cartItem: (state) => state.live.cart,
    }),
  },
  watch: {
    // 배당변경시 자동으로 최대 가능 베팅금액 변경
    odds: function (val) {
      if (Number(this.maxOdds) <= Number(val)) {
        this.cartItem.pop();
        return alert("최대 가능 배당을 초과했습니다.");
      }
      this.calWinnings();
    },
    // 베팅금액 초과시 자동으로 최대 가능 베팅금액 변경
    amount: {
      handler(val) {
        if (this.maxAmount < Number(val)) {
          this.amount = this.maxAmount;
          return alert("베팅금액은 최대베팅금액을 초과할 수 없습니다.");
        }
        if (this.user.members_cash < Number(val)) {
          this.amount = this.user.members_cash;
          return alert("베팅금액은 보유금액을 초과할 수 없습니다.");
        }
        this.calWinnings();
      },
      immediate: true,
    },
    cartItem: {
      handler: function (val) {
        if (val.length === 0) {
          this.maxAmount = Number(this.maxNormalBetAmount);
        }
        if (val.length === 1) {
          this.maxAmount = Number(this.maxOneFolderAmount);
          this.limitWinnings = this.maxOneFolderWinning;
        }
        if (val.length > 1) {
          this.maxAmount = Number(this.maxNormalBetAmount);
          this.limitWinnings = this.maxWinAmount;
        }
        this.calWinnings();
      },
      immediate: true,
      deep: true,
    },
    // 당첨금 변동
    winnings: function (val) {
      if (Number(val) > this.limitWinnings) {
        this.amount = Math.floor(this.limitWinnings / this.odds);
        return this.$store.state.dispatch(
          "SHOW_ERR_MSG",
          `당첨금액은 ${this.$options.filters.makeComma(
            this.limitWinnings
          )}을 초과할 수 없습니다.`
        );
      }
    },
    isActiveFreeBet: function (val) {
      if (!val) {
        this.selectedFreeBet = null;
      }
    },
    selectedFreeBet: function (val) {
      console.log(val);
      if (val) {
        this.amount = val.amount;
      }
    },
  },
  methods: {
    toggleCart: function () {
      return this.$store.dispatch("SHOW_MODAL", "cart");
    },
    closeCart: function () {
      return this.$store.dispatch("INVISIBLE_ALL_MODALS");
    },
    deleteItemCart: function (obj) {
      if (this.cartItem.length === 1) this.closeCart();
      return this.$store.dispatch("DELETE_LSPORT_CART_ITEM", obj);
    },
    clearItemCart: function () {
      this.closeCart();
      return this.$store.dispatch('ALL_LSPORT_CLEAR_ITEM')
    },  
    maxBet: function () {
      const values = [
        Math.floor(this.limitWinnings / this.odds),
        this.maxAmount,
        this.user.members_cash,
      ];
      this.amount = Math.min.apply(null, values);
      // if (this.winnings < this.limitWinnings){
      //   this.amount = Math.floor(this.limitWinnings / this.odds);
      // }
    },
    processBet: function () {
      let data = {};
      if (this.amount < this.minAmount)
        return alert(
          `최소 베팅금은 ${this.$options.filters.makeComma(
            this.minAmount
          )}원 입니다.`
        );
      if (
        this.cartItem.filter((e) => e.leagueNameEn !== "보너스").length === 1 &&
        ["", 0, "0"].indexOf(
          this.user.members_detail_setting.베팅차감.스포츠.one
        ) < 0
      ) {
        let confirmMsg = `단폴 베팅시 ${this.user.members_detail_setting.베팅차감.스포츠.one}% 배당 하락이 발생합니다.`;
        const oneConfirm = confirm(confirmMsg);
        if (!oneConfirm) {
          return;
        }
        this.betDeduction(
          this.user.members_detail_setting.베팅차감.스포츠.one,
          "유저설정-단폴베팅"
        );
      } else {
        if (
          this.cartItem.filter((e) => e.leagueNameEn !== "보너스").length ===
            1 &&
          this.oneFolderDeduction
        ) {
          let confirmMsg = `단폴 베팅시 ${this.oneFolderDeduction}% 배당 하락이 발생합니다.`;
          const singleConfirm = confirm(confirmMsg);
          if (!singleConfirm) {
            return;
          }
          this.singleBetDeduction();
        }
      }

      if (
        this.cartItem.filter((e) => e.leagueNameEn !== "보너스").length === 2 &&
        ["", 0, "0"].indexOf(
          this.user.members_detail_setting.베팅차감.스포츠.two
        ) < 0
      ) {
        let confirmMsg = `투폴 베팅시 ${this.user.members_detail_setting.베팅차감.스포츠.two}% 배당 하락이 발생합니다.`;
        const doubleConfirm = confirm(confirmMsg);
        if (!doubleConfirm) {
          return;
        }
        this.betDeduction(
          this.user.members_detail_setting.베팅차감.스포츠.two,
          "유저설정-투폴베팅"
        );
      } else {
        if (
          this.cartItem.filter((e) => e.leagueNameEn !== "보너스").length ===
            2 &&
          this.twoFolderDeduction
        ) {
          let confirmMsg = `투폴 베팅시 ${this.twoFolderDeduction}% 배당 하락이 발생합니다.`;
          const doubleConfirm = confirm(confirmMsg);
          if (!doubleConfirm) {
            return;
          }
          this.doubleBetDeduction();
        }
      }

      if (
        this.cartItem.filter((e) => e.leagueNameEn !== "보너스").length === 3 &&
        ["", 0, "0"].indexOf(
          this.user.members_detail_setting.베팅차감.스포츠.three
        ) < 0
      ) {
        let confirmMsg = `세폴 베팅시 ${this.user.members_detail_setting.베팅차감.스포츠.three}% 배당 하락이 발생합니다.`;
        const doubleConfirm = confirm(confirmMsg);
        if (!doubleConfirm) {
          return;
        }
        this.betDeduction(
          this.user.members_detail_setting.베팅차감.스포츠.three,
          "유저설정-세폴베팅"
        );
      }

      if (
        this.cartItem.filter((e) => e.leagueNameEn !== "보너스").length > 3 &&
        this.user.is_discount_three_over === "Y"
      ) {
        let confirmMsg = `세폴 베팅 초과시 ${this.user.members_detail_setting.베팅차감.스포츠.three}% 배당 하락이 발생합니다.`;
        const doubleConfirm = confirm(confirmMsg);
        if (!doubleConfirm) return;
        this.betDeduction(
          this.user.members_detail_setting.베팅차감.스포츠.three,
          "유저설정-세폴초과베팅"
        );
      }

      if (this.isActiveFreeBet && !this.selectedFreeBet) {
        return alert("프리벳을 선택후 진행해주세요");
      }

      data.bettings = this.cartItem;
      data.amount = this.amount;
      data.totalOdds = this.odds;
      data.winnings = this.winnings;
      data.process_freebet = this.isActiveFreeBet;
      data.selected_freebet = this.selectedFreeBet;
      this.$store.dispatch("SET_PROCESS", data);

      if (this.cartItem.find((b) => b.betsStatus === "y")) {
        this.$store.dispatch("SHOW_ERR_MSG", MSG.notAvailableBet);
        this.$store.dispatch("SHOW_MODAL", "bettingStatus");
        return;
      }
      // if (this.odds > this.maxOdds) return this.$store.dispatch('SHOW_ERR_MSG', '최대 배당을 초과하여 베팅할 수 없습니다.')
      if (this.odds > this.maxOdds) {
        this.$store.dispatch(
          "SHOW_ERR_MSG",
          "최대 배당을 초과하여 베팅할 수 없습니다."
        );
        return;
      }
      if (
        !this.isUpdate &&
        this.cartItem.find(
          (e) =>
            String(e.betsPrevOdds) !== String(e.odds) && e.betType !== "보너스"
        )
      ) {
        this.$store.dispatch("SHOW_MODAL", "bettingUpdate");
        return;
      }

      if (
        this.isUpdate &&
        this.cartItem.find(
          (e) =>
            String(e.betsPrevOdds) !== String(e.odds) && e.betType !== "보너스"
        )
      ) {
        this.$store.dispatch("SHOW_MODAL", "bettingUpdateProcess");
        return;
      }

      this.$store.dispatch('INVISIBLE_ALL_MODALS')
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessLiveMulti')
      //다폴더

      this.amount = 0;
      this.selected_freebet = null;
      this.$store.dispatch("ALL_LSPORT_CLEAR_ITEM");
    },
    calWinnings: function () {
      this.winnings = Math.floor(this.amount * this.odds);
    },
    betDeduction: function (penalty, reason) {
      for (const c in this.cartItem) {
        this.cartItem[c].penalty = penalty;
        this.cartItem[c].penaltyReason = reason;
      }
    },
    singleBetDeduction: function () {
      for (const c in this.cartItem) {
        this.cartItem[c].penalty = this.oneFolderDeduction;
        this.cartItem[c].penaltyReason = "단폴 베팅";
      }
    },
    doubleBetDeduction: function () {
      for (const c in this.cartItem) {
        this.cartItem[c].penalty = this.twoFolderDeduction;
        this.cartItem[c].penaltyReason = "투폴 베팅";
      }
    },
    addAmount(amt) {
      this.amount += amt;
    },
    resetAmount() {
      this.amount = 0;
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.bet-slip-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: block;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background-color: black;
  padding-top: 50px;
  overflow-y: auto;
}

.content {
  width: 100%;
  flex: 1;
  padding-bottom: 20px; /* Space for the footer */
}

.title-bar {
  background-color: black;
  padding: 10px 10px 10px 30px;
  text-align: center;
  font-size: 20px;
}

.bet-slip {
  background-color: black;
  padding: 10px;
  border-radius: 5px;
  /* margin-bottom: 20px; */
}

.bet-slip .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.bet-slip .header .title {
  font-size: 18px;
}

.bet-slip .header .toggle {
  display: flex;
  align-items: center;
}

.bet-slip .header .toggle input {
  margin-left: 10px;
}

.bet-slip .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #444;
}

.bet-slip .item:last-child {
  border-bottom: none;
}

.bet-slip .item .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bet-slip .item .info .icon {
  padding-bottom: 10px;
  /* margin-bottom: 10px; */
}

.bet-slip .item .info .details {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bet-slip .item .info .details .team {
  font-size: 14px;
  width: 100%;
}

.bet-slip .item .info .details .match {
  height: 35px;
  font-size: 14px;
  color: white;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 5px;
  width: 100%;
}

.bet-slip .item .info .details .match2 {
  font-size: 14px;
  color: white;
  /* display: flex; */
  /* justify-content: space-between; */
  align-items: center;
  /* gap: 75%; */
  width: 100%;
}

.bet-slip .item .info .details .match .odds {
  background-color: #a97bf5;
  padding: 5px 10px;
  border-radius: 5px;
  color: #ffffff;
  margin-left: 10px;
}

.cart-count {
  background-color: #a97bf5;
  padding: 2px 8px;
  border-radius: 14px;
  color: #ffffff;
  /* margin-left: 10px; */
}

.close-X {
  width: 10px;
  color: #a97bf5;
  padding: 5px 10px;
  border-radius: 5px;
  /* margin-left: 10px; */
  float: right;
  font-size: 20px;
}

.bet-slip .item .delete {
  margin-left: 10px;
  cursor: pointer;
}

.footer {
  background-color: black;
  width: 100%;
  /* padding: 10px; */
  border-radius: 5px;
  width: calc(100% - 20px); /* Adjust width to fit within the content area */
  /* margin: 0 auto;  */
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 2px solid #444;
}

.footer .free-bet {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  gap: 69%;
  align-items: center;
  margin-bottom: 10px;
  background-color: #a97bf5 !important;
}

.footer .free-bet .title {
  font-size: 16px;
  color: white;
}
.free-bet-select {
  width: 100%;
  font-size: 16px;
  background-color: #444;
  margin-bottom: 3px;
}

.footer .total {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.footer .total .label {
  font-size: 16px;
  margin-left: 9px;
}

.footer .total .value {
  font-size: 16px;
  margin-right: 9px;
}

.footer .amounts {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  border-top: 1px solid #444;
  padding-top: 10px;
}

.footer .amounts .amount {
  background-color: #444;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 18%;
  text-align: center;
}

.footer .buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.footer .buttons .button {
  border: 2px solid #a97bf5;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 40%;
  text-align: center;
  background-color: transparent;
  color: #ffffff;
}

.footer .bet-button {
  background-color: #a97bf5;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 95%;
  text-align: center;
  color: #ffffff;
  /* margin-top: 10px; */
}

.toggle-container .toggle {
  display: flex;
  align-items: center;
}
.toggle-container .toggle input {
  display: none;
}
.toggle-container .toggle label {
  background-color: #555;
  border-radius: 15px;
  cursor: pointer;
  height: 20px !important;
  position: relative;
  width: 40px !important;
}
.toggle-container .toggle label::after {
  background-color: #ccc;
  border-radius: 50%;
  content: "";
  height: 16px !important;
  left: 2px;
  position: absolute;
  top: 2px;
  transition: 0.3s;
  width: 16px !important;
}
.toggle-container .toggle input:checked + label {
  background-color: #a97bf5;
}
.toggle-container .toggle input:checked + label::after {
  left: 22px;
}
.border-b {
  border-bottom: 1px solid #444;
}
.mt-10 {
  margin-top: 10px;
}
</style>
