<template>
  <section class="main" v-if="!this.$store.state.page.isMobile">
    <EventPopup></EventPopup>
    <div id="contents_wrap">
      <table style="width: 100%; align-content: center">
        <tbody>
          <tr>
            <td style="vertical-align: top; padding-right: 10px; padding-left: 10px; width: 240px; ">
              <div class="image-map-container">
                <div style="width: 240px; height: 540px">
                  <!-- <img src="@/assets/img/main/ibet_main_left.png" alt="" usemap="#image-map"> -->
                  <a href="https://t.me/+1YKXb9HKtFI4ZmE0">
                    <img src="@/assets/img/main/좌측공지방배너.png">
                  </a>
                  <a href="https://t.me/+1YKXb9HKtFI4ZmE0">
                    <img src="@/assets/img/main/좌측평생도메인배너.png">
                  </a>
                </div>
                <div class="map-selector"></div>
              </div>
              <map name="image-map" id="image-map"> </map>
            </td>
            <td style="width: 1330px">
              <div class="contents">
                <div class="contents_in_m20">
                  <!-- 스와이퍼-->
                  <div class="main_visual">
                    <Swiper :options="swiperOptions">
                      <Swiper-slide>
                        <router-link to="/main">
                          <img src="@/assets/img/main/banner-luna-pc.png"/>
                        </router-link>
                      </Swiper-slide>
                      <Swiper-slide>
                        <router-link to="/casino/live">
                          <img src="@/assets/img/main/luna - 에볼루션.png" style="width:930px">
                        </router-link>
                      </Swiper-slide>
                    </Swiper>
                    <!--                  <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>-->
                  </div>
                  <div class="video_wrap ng-scope">

                  </div>

                  <!-- 인기경기 -->
                  <Popular></Popular>
                  <div class="contents_in_m20">
                    <div class="main_board_wrap">
                      <div class="main_title_2" id="tooltip1" dx-tooltip="defaultModeOptions">
                        실시간 환전현황
                      </div>
                      <div class="main_board">
                        <div id="tableTicker_wrap3" style=" width: 100%; height: 209px; position: relative; overflow: hidden;">
                          <table width="100%" cellpadding="0" cellspacing="0" class="myTable11" style="   position: absolute;   top: 0px;   margin-top: 0px; ">
                            <tbody>
                              <tr class="ng-scope">
                                <td>
                                  <table width="100%" cellpadding="0" cellspacing="0" class="board_table_1_1">
                                    <WithdrawalList></WithdrawalList>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <InpAutoScroll></InpAutoScroll>
                    <PreAutoScroll></PreAutoScroll>
                  </div>
                </div>
              </div>
            </td>
            <td style="vertical-align: top; padding-left: 10px; padding-right: 10px; width: 240px; text-align: right; ">
              <div class="image-map-container" style="width: 240px">
                <img
                  src="@/assets/img/main/메인우측배너.png"
                  alt=""
                  usemap="#image-map"
                />
                <div class="map-selector"></div>
              </div>
              <map name="image-map" id="image-map"> </map>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- wrap -->
    </div>
  </section>
  <div class="m-main" v-else>
    <MEventPopup></MEventPopup>
    <div class="m-visual">
      <Swiper :options="swiperOptions">
        <!--<Swiper-slide>-->
        <!--  <router-link to="/main">-->
        <!--    <img src="@/assets/img/main/kiki_renew_mobile.png" />-->
        <!--  </router-link>-->
        <!--</Swiper-slide>-->
        <Swiper-slide
          ><router-link to="/casino/home"
            ><img src="@/assets/img/main/banner-luna-mobile.png" /></router-link
        ></Swiper-slide>
        <Swiper-slide
          ><router-link to="/casino/home"
            ><img src="@/assets/img/main/1119.jpg" /></router-link
        ></Swiper-slide>
      </Swiper>
<!--      <div-->
<!--        class="swiper-pagination swiper-pagination-white"-->
<!--        slot="pagination"-->
<!--      ></div>-->
<!--      <div class="swiper-button-prev" slot="button-prev"></div>-->
<!--      <div class="swiper-button-next" slot="button-next"></div>-->
    </div>
    <div class="main-btns">
      <router-link v-if="inplayVisible" to="/sport/livematch"
        ><img src="@/assets/img/main/main3.png" /><strong
          >인플레이</strong
        ></router-link
      >
      <router-link v-if="liveVisible" to="/sport/live2"
        ><img src="@/assets/img/main/main1.png" /><strong
          >실시간</strong
        ></router-link
      >
      <router-link v-if="europeanVisible" to="/sport/lsport/prematch"
        ><img src="@/assets/img/main/main4.png" /><strong
          >유럽형</strong
        ></router-link
      >
      <router-link v-if="koreanVisible" to="/sport/korean"
        ><img src="@/assets/img/main/main2.png" /><strong
          >한국형</strong
        ></router-link
      >
      <!--      <a v-on:click="movePage('')"><img src="@/assets/img/main/main3.png"><strong>스포츠(한국형)</strong></a>-->
      <!--      <a v-on:click="movePage('')"><img src="@/assets/img/main/main4.png"><strong>스포츠(유럽형)</strong></a>-->
      <router-link to="/casino/mlive"
        ><img src="@/assets/img/main/main6.png" /><strong
          >카지노</strong
        ></router-link
      >
      <router-link to="/casino/mslot"
        ><img src="@/assets/img/main/main9.jpg" /><strong
          >슬롯게임</strong
        ></router-link
      >
      <router-link to="/mini/home"
        ><img src="@/assets/img/main/main5.png" /><strong
          >미니게임</strong
        ></router-link
      >
      <router-link to="/token/home"
        ><img src="@/assets/img/main/main7.png" /><strong
          >토큰게임</strong
        ></router-link
      >
      <!--      <a v-on:click="movePage('minigame/home')"><img src="@/assets/img/main/main8.png"><strong>로투스</strong></a>-->

    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import EventPopup from "@/components/EventPopup";
import MEventPopup from "@/components/MEventPopup";
import WithdrawalList from "@/components/WithdrawalList";
import PreAutoScroll from "@/components/PreAutoScroll";
import InpAutoScroll from "@/components/InpAutoScroll";
import Popular from "@/components/Popular";
import {mapState} from "vuex";
// import Typewriter from "typewriter-vue";

export default {
  name: "Index",
  components: {
    Swiper,
    SwiperSlide,
    EventPopup,
    MEventPopup,
    WithdrawalList,
    InpAutoScroll,
    PreAutoScroll,
    Popular,
    // Typewriter
  },
  data: () => {
    return {
      src: "https://static-sdsoft.s3.ap-northeast-1.amazonaws.com/video.mp4",
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 1500,
          disableOnInteraction: true,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "bullets",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      notice: [],
      event: [],
      popular: [],
      replace: [
        { from: "SOCCER", to: "BASEBALL" },
        { from: "BASEBALL", to: "RUGBY" },
        { from: "RUGBY", to: "BRING IT ON." },
      ],
    };
  },
  created() {
    if(!this.user) {
      window.__lc = window.__lc || {};
    window.__lc.license = 15733809;
    (function (n, t, c) {
      function i(n) {
        return e._h ? e._h.apply(null, n) : e._q.push(n);
      }
      var e = {
        _q: [],
        _h: null,
        _v: "2.0",
        on: function () {
          i(["on", c.call(arguments)]);
        },
        once: function () {
          i(["once", c.call(arguments)]);
        },
        off: function () {
          i(["off", c.call(arguments)]);
        },
        get: function () {
          if (!e._h)
            throw new Error(
              "[LiveChatWidget] You can't use getters before load."
            );
          return i(["get", c.call(arguments)]);
        },
        call: function () {
          i(["call", c.call(arguments)]);
        },
        init: function () {
          var n = t.createElement("script");
          (n.async = !0),
            (n.type = "text/javascript"),
            (n.src = "https://cdn.livechatinc.com/tracking.js"),
            (n.id = "chat"),
            t.head.appendChild(n);
        },
      };
      !n.__lc.asyncInit && e.init(), (n.LiveChatWidget = n.LiveChatWidget || e);
    })(window, document, [].slice);
    }
    window.scrollTo(0, 0);
    this.$forceUpdate();
    if(!this.user){
      console.log(4);
      this.visualizeChat();
    } 

  },
  watch:{
    user(val){
      if(val) {
        console.log(5);
        this.invisualizeChat()
      }
    }
  },
  methods: {
    invisualizeChat: function() {
            (function (n, t) {
          var e = {
            init: function () {
              var n = t.getElementById("chat-widget-container");
              if(n){
                n.style.visibility="hidden";
              }
            },
          };
          !n.__lc.asyncInit && e.init(), (n.LiveChatWidget = n.LiveChatWidget || e);
        })(window, document, [].slice);

    },
    visualizeChat: function() {
      console.log('실행')
      if(!this.user) {
            (function (n, t) {
          var e = {
            init: function () {
              var n = t.getElementById("chat-widget-container");
              if(n){
                n.style.visibility="visible";
              }
            },
          };
          !n.__lc.asyncInit && e.init(), (n.LiveChatWidget = n.LiveChatWidget || e);
        })(window, document, [].slice);
      }
    },
  },
  computed: {
    menuVisible() {
      return this.$store.getters["MENU_VISIBLE"];
    },
    inplayVisible() {
      return this.menuVisible?.find((e) => e.name === "인플레이").visible;
    },
    europeanVisible() {
      return this.menuVisible?.find((e) => e.name === "스포츠-유럽형").visible;
    },
    koreanVisible() {
      return this.menuVisible?.find((e) => e.name === "스포츠-한국형").visible;
    },
    korean2Visible() {
      return this.menuVisible?.find((e) => e.name === "스포츠-한국형2").visible;
    },
    liveVisible() {
      return this.menuVisible?.find((e) => e.name === "라이브").visible;
    },
    ...mapState({
      user: state => state.user.user,
    }),
  },
  beforeDestroy(){
    this.invisualizeChat()
  }
};
</script>

<style scoped>
/* visual */
.main_visual {
  width: 930px;
  float: left;
}

/*-------------------------------------------------------------------------------------*
 *  Contents                                                                           *
 *-------------------------------------------------------------------------------------*/
#contents_wrap {
  float: left;
  width: 100%;
  padding: 30px 0 50px 0;
}
.contents {
  width: 1330px;
  margin: 0px auto;
  min-height: 600px;
}
.contents_in_m20 {
  width: 100%;
  float: left;
  margin: 0 0 20px 0;
}

.image-map-container {
  position: relative;
  display: inline-block;
}

map {
  display: inline;
}

/* tab */
#tabs ul,
#tabs2 ul {
  width: 100%;
  float: left;
  background: #293133;
  margin: 0 0 1px 0;
}
#tabs li,
#tabs2 li {
  float: left;
  height: 34px;
  margin: 0;
}
#tabs li a,
#tabs2 li a {
  display: block;
  padding: 0 8px 0 8px;
  background: #293133;
  text-decoration: none;
  color: #c1c3c4;
  height: 34px;
  line-height: 34px;
  text-align: center;
}
#tabs li a:hover,
#tabs2 li a:hover {
  background: #222627;
  color: #c1c3c4;
}
#tabs_container div {
  width: 100%;
}

.main_board_wrap {
  width: 434px;
  float: left;
  margin: 0 14px 0 0;
}
.main_title_2 {
  width: 432px;
  height: 38px;
  float: left;
  border: solid 1px #262a2a;
  color: #b7b7b7;
  line-height: 38px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -1px;
  text-indent: 20px;
  margin: 0 0 10px 0;
  position: relative;
}
.deadline li {
  float: left;
  text-indent: 0px;
  margin: 0 0 0 20px;
}
.deadline li.time_on {
  border-bottom: solid 2px #a97bf5;
}
.deadline li.time_on a {
  color: #ffffff;
}
.main_board {
  width: 100%;
  float: left;
  box-sizing: border-box;
}
.board_table_1 tr td {
  height: 34px;
  text-align: center;
}

.board_table_2 tr td {
  height: 34px;
  text-align: center;
}

.swiper-pagination {
  position: absolute;
  display: block;
  right: 12px;
  bottom: 14px;
  left: 428px;
  width: 74px;
  height: 18px;
}

.main-btns a {
  width: 50%;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
}

.main-btns a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.7);
}

.image-map-container {
  position: relative;
  display: inline-block;
}

.video_wrap {
  width: 400px;
  height: 225px;
  float: left;
  overflow: hidden;
}
.typewriter{
  position: absolute; top: 31%;
  padding-left: 7.5%;
}
.video_wrap span {
  color: #FFFFFF;
  font-size: 30px;
}
</style>
